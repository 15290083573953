import { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "../../hooks/useQuery";
import { personaKey } from "../../commons/keys";
import useSocket from "../../util/useSocket";
import {
  getAssistantURL,
  getInitialFollowupQuestionsFromPersona,
  getPersonaNameFromCode,
} from "../../commons/getAssistantURL";
import CentralLoading from "../../components/loader/centralLoading";
import { ChatSidebar } from "../../components/chat/sidebar";
import { useNavigate } from "react-router-dom";
import { ChatContainer } from "../../components/chat/container";
import { ChatInput } from "../../components/chat/chatInput";
import AlwaysScrollToBottom from "../../components/scroll/scrollToBottom";
import { BeatLoader } from "react-spinners";
import { theme } from "../../commons/styles";
import { ChatNavbar } from "../../components/chat/chatNavbar";
import { AIBlock, UserBlock } from "../../components/chat/chatBlock";
import { transcribeAudio } from "../../actions/audio";
import { errorToast, successToast } from "../../util/toasts";
import { withOrg } from "../../components/organisation/withOrg";
import { convertToJsonFormat } from "../../util/convert";

import { FollowUpQuestions } from "../../components/chat/follow_questions";
import { replaceKeys } from "../../util/replaceObjKey";
import {
  createExternalLinkForSharing,
  deleteFactlyPersonaHistory,
  getFactlyPersonaHistory,
  limitReached as limitReachAction,
  validateAccess,
} from "../../actions/persona";
import { UnauthorisedAccess } from "../errors/unauthorised";
import Modal from "../../components/Modal";
import UppyUploader from "../../components/Uppy";
import { getFileExtensionFromMime } from "../../util/fileType";
import { MaxLimitReachedPersona } from "../../components/commons/maxLimitPersonaWarning";
import { MaxLimitWarning } from "../../components/commons/maxLimitWarning";
import { isObject } from "../../util/isObject";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { usePostHog } from "posthog-js/react";
import { dispatchPosthog } from "../../util/posthog";

function FactlyPersona({ userID, selectedOrg }) {
  // posthog is used to track the events
  const posthog = usePostHog();

  // currentChat is used to store the current chat
  const [currentChat, setCurrentChat] = useState({ id: "", title: "" });

  // isSelectedChat is used to check whether the current chat is selected from the sidebar
  const [isSelectedChat, setIsSelectedChat] = useState(false);

  // navigate is used to navigate to a different route
  const navigate = useNavigate();

  // useQuery returns a URLSearchParams object which has a get() method with key as argument
  const query = useQuery();

  const { personaIDQuery, personaRequestURL } = useMemo(() => {
    const personaName = query.get(personaKey);
    let requestURL = `${getAssistantURL(
      personaName
    )}?user_id=${userID}&llm_model=gpt4_turbo`;

    if (currentChat.id !== "") {
      requestURL += `&chat_id=${currentChat.id}`;
    }

    return {
      personaIDQuery: personaName,
      personaRequestURL: requestURL,
    };
  }, [query, currentChat]);

  // sidebarVisible is used to toggle the sidebar
  const [sidebarVisible, setSidebarVisible] = useState(true);

  // sharedLink is used to store the shared link
  const [sharedLink, setSharedLink] = useState("");

  // showShareChat is used to toggle the share chat modal
  const [showShareChat, setShowShareChat] = useState(false);

  // toggleSidebar is used to toggle the sidebar
  const toggleSidebar = () => setSidebarVisible(!sidebarVisible);

  // handleNewChatClick is used to handle the click on the new chat button in the sidebar
  const handleNewChatClick = () => {
    setIsSelectedChat(false);

    setCurrentChat({ id: "", title: "" });

    setMessages([]);

    // disconnect with the current socket
    disconnect();

    setFollowups(getInitialFollowupQuestionsFromPersona(personaIDQuery));

    setUsrMsgs(0);
    // set the current chat to empty
    setLoading(false);

    limitReachAction(personaIDQuery)
      .then((response) => {
        setLimitReached(response?.hasReachedLimit);
      })
      .catch((error) => {
        console.log(error);
      });

    fetchChatHistory();
  };

  function getPersonaDescription() {
    switch (personaIDQuery) {
      case "parlens":
        return "Engage with the wealth of Lok Sabha and Rajya Sabha data effortlessly with Parlens, your conversational guide to parliamentary knowledge.";
      case "budgetspeech":
        return "Delve into the heart of India's financial decisions with BudgetSpeak, where budget speeches come alive through insightful conversations.";
      case "pib":
        return "Journey through the present and past with PIB Chronicle, your gateway to daily and historical insights from the Government of India's press releases.";
      default:
        return "Empowering Users to Navigate Fact and Fiction. Your Gateway to Verified Information and Debunked Hoaxes from Claim Review.";
    }
  }

  const [followups, setFollowups] = useState([]);

  const [model, setModel] = useState("gpt4_turbo");

  const [showUppy, setShowUppy] = useState(false);

  // messages is used to store the messages in the current chat
  const [messages, setMessages] = useState([]);

  // currentPrompt is used to store the current prompt
  const [currentPrompt, setCurrentPrompt] = useState("");

  // chatHistory is used to store the chat history
  const [chatHistory, setChatHistory] = useState({
    count: 0,
    results: [],
  });

  const [loading, setLoading] = useState(false);

  // editIndex is used to store the index of the message being edited
  const [editIndex, setEditIndex] = useState(-1);

  const [access, setAccess] = useState(true);

  // showFilesMenu is used to toggle the files menu
  const [showFilesMenu, setShowFilesMenu] = useState(false);

  const toggleFilesMenu = () => {
    setShowFilesMenu(!showFilesMenu);
  };

  // userClickedClose is used to check whether user has clicked on closing the restriction button
  const [userClickedClose, setUserClickedClose] = useState(false);

  // selectedFiles is used to store the files selected by the user
  const [selectedFiles, setSelectedFiles] = useState([]);

  const fetchAccess = useCallback(async () => {
    if (personaIDQuery) {
      validateAccess({
        orgID: selectedOrg,
        userID: userID,
        personaName: personaIDQuery,
      })
        .then((response) => {
          setAccess(response?.allowed);
        })
        .catch(() => {
          errorToast(
            "Something went wrong. Please try again after some time or reload the page."
          );
        });
    }
  }, [personaIDQuery]);

  useEffect(() => {
    fetchAccess();

    setFollowups(getInitialFollowupQuestionsFromPersona(personaIDQuery));
  }, [personaIDQuery]);

  const callBackHandler = (event) => {
    const data = JSON.parse(event.data);
    processWebSocketResponse(data);
  };

  const { socket, isConnected, connect, disconnect } = useSocket(
    personaRequestURL,
    callBackHandler
  );

  // handleKeyDown is used to handle the keydown event for enter key
  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && e.shiftKey) {
      return;
    }

    if (e.keyCode === 13 && !loading && currentPrompt !== "") {
      handleChatSubmit();
    }
  };

  // sendMessage is used to send the message to the socket
  const sendMessage = (content) => {
    if (socket && content) {
      let message = {};

      message.input = content.content;

      if (content?.files) {
        let files = content.files.map((file) => {
          return {
            type: getFileExtensionFromMime(file.type),
            url: file.url.raw,
          };
        });
        message.files = files;
      } else {
        if (personaIDQuery) {
          message.files = [];
        }
      }

      socket.send(JSON.stringify(message));
      setCurrentPrompt("");
    }
  };

  const handleSendMessage = (lastMessage) => {
    let newMessage = {
      content: lastMessage ? lastMessage : currentPrompt,
      sender: "human",
    };

    if (selectedFiles.length > 0) {
      newMessage.files = selectedFiles;
    }

    setLoading(true);
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setSelectedFiles([]);

    setUsrMsgs(usrMsgs + 1);

    sendMessage({
      content: newMessage.content,
      files: newMessage.files,
    });
  };

  const [limitReached, setLimitReached] = useState(false);
  const [usrMsgs, setUsrMsgs] = useState(0);

  const handleChatSubmit = () => {
    handleSendMessage(currentPrompt);

    dispatchPosthog(posthog, "Sent Factly Persona Message", {
      persona: personaIDQuery,
      message: currentPrompt,
    });
  };

  const processWebSocketResponse = (data) => {
    if (data?.chat_id) {
      setCurrentChat({ id: data?.chat_id, title: data?.chat_title });
    }

    if (data.type === "body") {
      return; // Ignore body messages
    }

    setMessages((prevMessages) => {
      let updatedMessages = [...prevMessages]; // Create a copy of the previous state
      switch (data.sender) {
        case "ai":
          switch (data.type) {
            case "start":
              // Start a new AI message
              updatedMessages.push({ sender: "ai", content: "" });
              break;
            case "stream":
              // Combine content of stream messages into the latest AI message
              const aiMessages = updatedMessages.filter(
                (message) => message.sender === "ai"
              );
              const lastAiMessage = aiMessages[aiMessages.length - 1];
              if (lastAiMessage) {
                lastAiMessage.content += data.content;
              }
              break;
            case "end":
              // Finish the last AI message by trimming trailing whitespace
              const aiMessagesEnd = updatedMessages.filter(
                (message) => message.sender === "ai"
              );
              const lastAiMessageEnd = aiMessagesEnd[aiMessagesEnd.length - 1];
              if (lastAiMessageEnd) {
                lastAiMessageEnd.content = lastAiMessageEnd.content.trim();
              }

              // let para = convertToJsonFormat(updatedMessages);
              // let request = {
              //   provider: "openai",
              //   input: para,
              //   model: "gpt-4",
              //   stream: false,
              //   generate_for: "followup-questions-chat",
              // };

              limitReachAction(personaIDQuery)
                .then((response) => {
                  setLimitReached(response?.hasReachedLimit);
                })
                .catch((error) => {
                  console.log(error);
                });
              setLoading(false);
              break;
            default:
              break;
          }
          break;
        case "human":
          // Add user message to the messages array
          const userMessage = { sender: "human", content: data.content };
          updatedMessages.push(userMessage);
          break;
        default:
          break;
      }

      return updatedMessages; // Return the updated state
    });
  };

  const getAudioBlob = async (audioURL) => {
    let audioBlob = new Blob(
      [new Uint8Array(await (await fetch(audioURL)).arrayBuffer())],
      { type: "audio/webm " }
    );

    // making a file out of this blob
    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    const sampleRate = audioContext.sampleRate;

    const formData = new FormData();
    formData.append("sampleRate", sampleRate); // Append the sample rate
    formData.append("audio", audioBlob);

    handleAudioSubmit(formData);
  };

  const handleEdit = (text, index) => {
    setMessages(messages.slice(0, index));

    setTimeout(() => {
      handleSendMessage(text);
    }, 500);
  };

  const handleRegenerate = () => {
    setLoading(true);
    let userMessage = messages[messages.length - 2].content;
    let newMessages = messages.slice(0, messages.length - 2);
    setMessages(newMessages);
    handleSendMessage(userMessage);
  };

  const handleAudioSubmit = (formData) => {
    setLoading(true);
    transcribeAudio(formData)
      .then((data) => {
        handleSendMessage(data?.transcript);
      })
      .catch((error) => {
        errorToast("Unable to generate response. Please try again");
      });
  };

  const [scrollUp, setScrollUp] = useState(false);

  const handleScroll = (e) => {
    if (!loading && e?.deltaY) {
      if (e?.deltaY < 0) {
        setScrollUp(true);
      } else {
        setScrollUp(false);
      }
    }
  };

  const pagination = {
    page: 1,
    limit: 20,
  };

  const fetchChatHistory = () => {
    if (personaIDQuery) {
      getFactlyPersonaHistory({
        persona: personaIDQuery,
        page: pagination.page,
        limit: pagination.limit,
      })
        .then((data) => {
          const normalizedData = (arr) => {
            return arr.map((originalObject) => {
              const keyMap = {
                chat_id: "id",
                chat_title: "title",
                chat_history: "messages",
              };
              return replaceKeys(originalObject, keyMap);
            });
          };
          setChatHistory({
            results: normalizedData(data),
            count: data.total || 20,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const fetchLimitDetails = () => {
    if (personaIDQuery) {
      limitReachAction(personaIDQuery)
        .then((response) => {
          setLimitReached(response?.hasReachedLimit);
        })
        .catch((error) => {
          console.log(error);
        });
      return;
    }
  };

  useEffect(() => {
    // chat history
    fetchChatHistory();

    // limit details
    fetchLimitDetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectChat = (chat) => {
    setFollowups([]);

    setIsSelectedChat(true);

    // disconnect with the current socket
    disconnect();
    setCurrentChat({ id: chat.id, title: chat.title });

    let messages = chat?.messages?.map((message) => {
      return {
        sender: message?.sender,
        content: isObject(message?.content)
          ? message?.content?.input
          : message?.content,
        files: message?.content?.files || [],
      };
    });

    let para = convertToJsonFormat(messages);
    let request = {
      provider: "openai",
      input: para,
      model: "gpt-4",
      stream: false,
      generate_for: "followup-questions-chat",
    };

    setUsrMsgs(messages.filter((message) => message.sender === "human").length);

    setMessages(messages);
  };

  if (!access) {
    return (
      <UnauthorisedAccess
        title="Access Denied"
        description="Oops! It seems like you don't have the necessary access to engage with this persona. Access to specific features or conversations may be restricted based on user roles or permissions. If you believe this is an error or if you need additional access, please contact your administrator for assistance. Thank you for your understanding!"
        showLogout={false}
        link={`/personas`}
        linkText={`Go back to Personas`}
      />
    );
  }

  // userHasReachedLimit function contains logic to check if the user has reached the limit of the number of conversations
  // max number of conversation in a day is 5 which we get to know from the limitReachedAction
  // if the limitReached returns true and the currentChat is nil then don't allow the user to start a new chat i.e. disable the chat input
  // if the limitReached returns true and the currentChat is not nil then allow the user to continue the chat
  // the max user messages allowed in 1 chat is 5
  function userHasReachedLimit() {
    if (limitReached) {
      return true;
    }

    return false;
  }

  const handleDeleteChat = (chatID) => {
    deleteFactlyPersonaHistory({
      persona: personaIDQuery,
      chat_id: chatID,
    })
      .then(() => {
        successToast("Chat deleted successfully");
        fetchChatHistory();
        setCurrentChat({ id: "", title: "" });
        setMessages([]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const isInputDisabled = () => {
    return (userHasReachedLimit() && usrMsgs >= 5) || !isConnected;
  };

  const toggleShareChat = () => {
    setShowShareChat(!showShareChat);
  };

  return (
    <div className="flex w-screen h-screen fixed">
      <ChatSidebar
        disableNewChat={userHasReachedLimit()}
        chatHistory={chatHistory}
        sidebarVisible={sidebarVisible}
        toggleSidebar={toggleSidebar}
        handleNewChat={handleNewChatClick}
        navigate={() => navigate("/personas")}
        handleSelectChat={handleSelectChat}
        chat={{ ...currentChat, messages: messages }}
        handleDeleteChat={handleDeleteChat}
        key={chatHistory?.count}
      />
      <ChatContainer sidebarVisible={sidebarVisible}>
        <ChatNavbar
          sidebarVisible={sidebarVisible}
          handleNewChat={handleNewChatClick}
          chat={{
            messages: messages,
          }}
          toggleSidebar={toggleSidebar}
          showSettingsIcon={false}
          showShare={false}
          isPersona={true}
          persona={getPersonaNameFromCode(personaIDQuery)}
          handlePersonaModel={(model) => {
            setModel(model);
          }}
          modelName={model}
          persona_description={getPersonaDescription()}
          showLimit={true}
          limit={5 - usrMsgs}
          isConnected={isConnected}
          handleShareLink={() => {
            if (messages.length <= 0) {
              errorToast("Please start a conversation before sharing the chat");
              return;
            }

            if (!currentChat?.id) {
              errorToast("Please select a chat before sharing");
              return;
            }

            createExternalLinkForSharing({
              persona_name: personaIDQuery,
              chat_id: currentChat.id,
            })
              .then((response) => {
                let link;
                if (process.env.NODE_ENV === "development") {
                  link = `${window.location.origin}${window.REACT_APP_PUBLIC_URL}/persona/shared/${response?.external_id}`;
                } else {
                  link = `${window.location.origin}/persona/shared/${response?.external_id}`;
                }
                setSharedLink(link);
                toggleShareChat();

                dispatchPosthog(posthog, "Shared Factly Persona Chat", {
                  persona: personaIDQuery,
                  chat_id: currentChat.id,
                  shared_link: link,
                });
              })
              .catch((error) => {
                console.log(error);
              });
          }}
        />
        <div className={`flex-[11] flex flex-col px-10 justify-center`}>
          {/* chat display section starts here */}
          <div
            className="flex-[10] py-8 px-9 flex flex-col gap-8 max-h-[80vh] overflow-y-scroll"
            onScroll={handleScroll}
            id="chat-display"
          >
            {messages
              .filter((message) => message.role !== "system")
              .map((message, index) => {
                if (message.sender === "human") {
                  return (
                    <UserBlock
                      key={index}
                      content={message?.content}
                      audioURL={message?.audioURL}
                      editing={index === editIndex}
                      onEditClick={() => {
                        setEditIndex(index);
                      }}
                      onEditCancel={() => setEditIndex(null)}
                      onEditSubmit={(text) => {
                        handleEdit(text, index);
                      }}
                      files={message?.files}
                    />
                  );
                } else if (message.sender === "ai") {
                  return (
                    <AIBlock
                      index={index}
                      content={message.content}
                      audioURL={message.audioURL}
                      showRegenerate={index === messages.length - 1 && !loading}
                      triggerRegenerate={handleRegenerate}
                      showAudio={false}
                    />
                  );
                }
              })}
            {!loading &&
              !userHasReachedLimit() &&
              usrMsgs < 5 &&
              selectedFiles.length === 0 &&
              isConnected &&
              !messages.length && (
                <FollowUpQuestions
                  followups={followups}
                  handleFollowUp={(followup) => {
                    handleSendMessage(followup);
                    setFollowups([]);
                  }}
                />
              )}
            {loading && !scrollUp && (
              <div className="flex justify-center mt-4">
                <AlwaysScrollToBottom chat={messages} />
                <BeatLoader size={theme.iconSize.large} color={"#CED0D4"} />
              </div>
            )}
          </div>
          {/* the chat input section starts here */}
          <div className="relative">
            {selectedFiles.length > 0 && (
              <div className="w-full grid grid-cols-3 absolute bottom-full max-h-96 bg-[#F9F9F9] border border-[#D1D1D1] overflow-y-scroll rounded-md p-2 gap-2">
                {selectedFiles.map((file, index) => {
                  return (
                    <div
                      className="flex flex-col gap-2 justify-center items-center"
                      key={index}
                    >
                      <img
                        src={file.url.raw}
                        alt={file.name}
                        className="object-cover w-full h-full"
                      />
                    </div>
                  );
                })}
              </div>
            )}
            {(userClickedClose && userHasReachedLimit() && !isSelectedChat) ||
            usrMsgs >= 5 ? (
              <MaxLimitWarning
                handleNewChat={() => {
                  handleNewChatClick();
                }}
                handleExplorePersonas={() => {
                  navigate("/personas");
                }}
                convLimit={userHasReachedLimit()}
              />
            ) : (
              <ChatInput
                loading={loading}
                currentPrompt={currentPrompt}
                setCurrentPrompt={setCurrentPrompt}
                handleKeyDown={handleKeyDown}
                getAudioBlob={getAudioBlob}
                handleChatSubmit={handleChatSubmit}
                showStop={true}
                handleStop={() => {
                  disconnect();
                  setLoading(false);
                  connect();
                }}
                handleImageUpload={() => {
                  setShowUppy(true);
                }}
                showAudio={false}
                showFiles={personaIDQuery === "sach"}
                showFilesMenu={showFilesMenu}
                toggleFilesMenu={toggleFilesMenu}
                disabled={isInputDisabled()}
              />
            )}
          </div>
        </div>
        {showUppy && (
          <Modal
            onClose={() => setShowUppy(false)}
            open={showUppy}
            closeButton={true}
          >
            <UppyUploader
              onUpload={(fileDetails) => {
                setShowUppy(false);
                setShowFilesMenu(false);
                setSelectedFiles([...selectedFiles, ...fileDetails]);
              }}
            />
          </Modal>
        )}
        {!userClickedClose && userHasReachedLimit() && (
          <Modal
            closeButton={true}
            open={true}
            onClose={() => {
              setUserClickedClose(true);
            }}
            width={"w-1/3"}
          >
            <MaxLimitReachedPersona
              handleNewChat={() => {
                handleNewChatClick();
                setUserClickedClose(true);
              }}
              handleExplorePersonas={() => {
                navigate("/personas");
              }}
              convLimit={userHasReachedLimit()}
            />
          </Modal>
        )}
      </ChatContainer>
      {showShareChat && (
        <div className="absolute w-screen h-screen bg-white opacity-90">
          <div
            className={`absolute top-16 right-16 p-6 flex flex-col bg-[#f9f9f9] border border-[#D1D1D1] rounded-md gap-4 z-[50] w-[500px]`}
          >
            <span>Share Link to this Chat</span>
            <div className="max-h-[320px] overflow-y-auto bg-white z-[999] w-full">
              {messages
                .filter((item) => item.sender !== "system")
                .map((item, index) => {
                  return (
                    <>
                      {item.sender === "human" ? (
                        <UserBlock
                          key={index}
                          content={item.content}
                          showEdit={false}
                          files={item?.files}
                        />
                      ) : (
                        <AIBlock
                          index={index}
                          content={item.content}
                          showAudio={false}
                        />
                      )}
                    </>
                  );
                })}
            </div>
            <span className="text-[#666]">
              Anyone with the URL will be able to view the shared chat but wont
              be able to make any changes.
            </span>
            <div className="flex items-center justify-center gap-4">
              <button
                className="px-2 py-3 border text-[#798897] border-[#798897] rounded-md"
                onClick={() => toggleShareChat()}
              >
                Cancel
              </button>
              <button
                className="px-2 py-3 bg-[#798897] text-white rounded-md"
                onClick={() => {
                  navigator.clipboard.writeText(sharedLink);
                  successToast("Link copied to clipboard");
                }}
              >
                Copy Link
              </button>
            </div>
          </div>
        </div>
      )}
      {/* </> */}
      <ToastContainer
        toastClassName={({ type }) =>
          type === "error"
            ? "w-[340px] border-l-[12px] border-[#DA3125] rounded-md shadow-lg bg-[#FFF]"
            : type === "success"
            ? "w-[340px] border-l-[12px] border-[#03C04A] rounded-md shadow-lg bg-[#FFF]"
            : type === "warning"
            ? "w-[340px] border-l-[12px] border-[#EA8700] rounded-md shadow-lg bg-[#FFF]"
            : ""
        }
        className="space-y-4  "
      />
    </div>
  );
}

const loader = (
  <div className="flex w-screen h-screen fixed">
    <CentralLoading />
  </div>
);

export default withOrg(FactlyPersona, loader);
