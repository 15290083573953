import { useEffect, useState } from "react";
import { ChatSidebar } from "../../components/chat/sidebar";
import { ChatNavbar } from "../../components/chat/chatNavbar";
import { useNavigate } from "react-router-dom";
import { getasstNamefromCode } from "../../commons/assistantName";
import { useQuery } from "../../hooks/useQuery";
import { ChatInput } from "../../components/chat/chatInput";
import { BeatLoader } from "react-spinners";
import AlwaysScrollToBottom from "../../components/scroll/scrollToBottom";
import { AIBlock, UserBlock } from "../../components/chat/chatBlock";
import { theme } from "../../commons/styles";
import {
  fetchAsstHistory,
  getResponseFromAsst,
  getThreadByID,
} from "../../actions/assistants";
import { errorToast } from "../../util/toasts";
import { withOrg } from "../../components/organisation/withOrg";
import { getInitialFollowupQuestionsFromPersona } from "../../commons/getAssistantURL";
// import { FollowUpQuestions } from "../../components/chat/follow_questions";

function AssistantChat({ selectedOrg }) {
  const navigate = useNavigate();
  const [sidebarVisible, setSidebarVisible] = useState(true);

  const query = useQuery();
  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const [thread, setThread] = useState({
    id: "",
    messages: [],
  });

  const [scrollUp, setScrollUp] = useState(false);

  // followup questions state and sets the initial followup questions
  const [followups, setFollowups] = useState([]);

  const handleScroll = (e) => {
    if (!loading) {
      if (e?.deltaY < 0 || isNaN(e.deltaY)) {
        setScrollUp(true);
      } else {
        setScrollUp(false);
      }
    }
  };

  useEffect(() => {
    // Attach the scroll event listener when the component mounts
    const scrollContainer = document.getElementById("chat-display");
    scrollContainer?.addEventListener("wheel", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      scrollContainer?.removeEventListener("wheel", handleScroll);
    };
  }, []);

  const handleKeyDown = (e) => {
    // add an event to handle shift + enter
    if (e.keyCode === 13 && e.shiftKey) {
      return;
    }

    if (e.keyCode === 13 && !loading && currentPrompt !== "") {
      handleChatSubmit();
    }
  };

  const [loading, setLoading] = useState(false);

  const [currentPrompt, setCurrentPrompt] = useState("");

  const handleChatSubmit = () => {
    setLoading(true);
    setThread({
      ...thread,
      messages: [
        ...thread.messages,
        {
          content: currentPrompt,
          role: "user",
        },
      ],
    });

    getResponseFromAsst({
      threadId: thread.id,
      prompt: currentPrompt,
      asstID: query.get("asst_id"),
      orgID: selectedOrg,
    })
      .then((data) => {
        setThread({
          id: data.thread_id,
          messages: data.messages,
        });
      })
      .catch((err) => {
        errorToast(err.message);
      })
      .finally(() => {
        setLoading(false);
      });

    setCurrentPrompt("");
  };

  const [searchQuery, setSearchQuery] = useState("");

  const [msgs, setMsgs] = useState([]);

  const fetchHistory = async () => {
    fetchAsstHistory({
      asstID: query.get("asst_id"),
      query: searchQuery,
      orgID: selectedOrg,
    })
      .then((data) => {
        setMsgs(data?.threads);
      })
      .catch((err) => {
        errorToast(err.message);
      });
  };

  useEffect(() => {
    let timer = setTimeout(() => {
      fetchHistory();
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [searchQuery]);

  useEffect(() => {
    const threadID = query.get("thread_id");
    if (threadID) {
      getThreadByID(threadID)
        .then((data) => {
          setThread({
            id: data.thread_id,
            messages: data.messages,
          });
        })
        .catch(() => {
          errorToast("Unable to fetch thread");
        });
    }
  }, [query.get("thread_id")]);

  useEffect(() => {
    setFollowups(getInitialFollowupQuestionsFromPersona(query.get("asst_id")));
  }, [query.get("asst_id")]);

  return (
    <div className="flex w-screen h-screen fixed">
      <ChatSidebar
        chat={thread}
        chatHistory={{
          results: msgs?.map((msg) => {
            return {
              id: msg.thread_id,
              ...msg,
            };
          }),
        }}
        sidebarVisible={sidebarVisible}
        toggleSidebar={toggleSidebar}
        navigate={() => navigate("/personas")}
        handleNewChat={() => {
          setThread({
            id: "",
            messages: [],
          });
        }}
        handleSelectChat={(chatItem) => {
          setThread({
            id: chatItem.id,
            messages: chatItem.messages,
          });
        }}
        setPagination={(e) => {
          setSearchQuery(e?.search_query);
        }}
      />
      <div
        className={`
        ${sidebarVisible ? "flex-[7]" : "w-full"} 
        h-full flex justify-between flex-col
      `}
      >
        <ChatNavbar
          sidebarVisible={sidebarVisible}
          showSettings={false}
          showSettingsIcon={false}
          showShare={false}
          persona={getasstNamefromCode(query.get("asst_id"))}
          toggleSidebar={toggleSidebar}
          isPersona={true}
          showLimit={false}
          isConnected={true}
        />
        <div className={`flex-[11] flex flex-col px-10 justify-center`}>
          {/* chat display section starts here */}
          <div
            className="flex-[10] py-8 px-9 flex flex-col gap-8 max-h-[80vh] overflow-y-scroll"
            onScroll={handleScroll}
            id="chat-display"
          >
            {thread.messages
              ?.filter((message) => message.role !== "system")
              ?.map((message, index) => {
                if (message.role === "user") {
                  return (
                    <UserBlock
                      key={index}
                      content={message?.content}
                      showEdit={false}
                    />
                  );
                } else {
                  return (
                    <AIBlock
                      showAudio={false}
                      index={index}
                      content={message.content}
                      showRegenerate={false}
                      triggerRegenerate={() => {}}
                    />
                  );
                }
              })}
            {/* {
              followups && (
                <FollowUpQuestions
                  followups={followups}
                  handleFollowUp={(followup) => {}}
                />
              )
            } */}
            {loading && !scrollUp && (
              <div className="flex justify-center mt-4">
                <AlwaysScrollToBottom chat={thread.messages} />
                <BeatLoader size={theme.iconSize.large} color={"#CED0D4"} />
              </div>
            )}
          </div>
          {/* the chat input section starts here */}
          <ChatInput
            loading={loading}
            currentPrompt={currentPrompt}
            setCurrentPrompt={setCurrentPrompt}
            handleKeyDown={handleKeyDown}
            getAudioBlob={() => {}}
            handleChatSubmit={handleChatSubmit}
            showStop={false}
            showAudio={false}
          />
        </div>
      </div>
    </div>
  );
}

export default withOrg(AssistantChat);
