import { AiOutlinePlus } from "react-icons/ai";
export function CreateButton({
  text = "Create",
  onClick = () => {},
  icon = true,
}) {
  return (
    <button
      type="button"
      className={` w-fit px-3 py-2 bg-black dark:bg-white  text-base dark:text-black text-white rounded-lg cursor-pointer flex flex-row items-center justify-center ${
        text ? "gap-2" : ""
      }`}
      onClick={onClick}
    >
      {icon && <AiOutlinePlus className="w-fit" />}
      <p className=" whitespace-nowrap hidden sm:block">{text}</p>
    </button>
  );
}
