import { useEffect, useState } from "react";
import { Input } from "../../components/inputs/Input";
import { maker } from "../../util/sluger";
import tagoreAILogo from "../../assets/icons/tagoreAiBlack.svg";
import { createOrganisation } from "../../actions/organisation";
import { useNavigate } from "react-router-dom";
import { errorToast, successToast } from "../../util/toasts";
import { ClipLoader } from "react-spinners";
import {
  acceptTagoreInvitation,
  getInvitations,
  rejectInvitation,
} from "../../actions/profile";
import { ToastContainer } from "react-toastify";

export default function Organisation() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({
    name: {
      value: "",
      error: "",
    },
    slug: {
      value: "",
      error: "",
    },

    description: {
      value: "",
      error: "",
    },
  });

  const validateForm = () => {
    let error = false;
    let newForm = { ...form };
    if (!form.name.value) {
      newForm.name.error = "Name cannot be empty";
      error = true;
    }
    if (!form.slug.value) {
      newForm.slug.error = "Slug cannot be empty";
      error = true;
    }

    setForm(newForm);
    return error;
  };

  const handleFormSubmit = () => {
    if (validateForm()) return;

    setLoading(true);
    const payload = {
      title: form.name.value,
      slug: form.slug.value,
      description: form.description.value,
    };

    createOrganisation(payload)
      .then((res) => {
        successToast("Organisation created successfully");
        setTimeout(() => {
          navigate("/");
        }, 1000);
      })
      .catch((error) => {
        errorToast("Unable to create organisation ", error?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [tab, setTab] = useState(1);

  const tabOptions = [
    {
      title: "Create Organisation",
      description:
        "Create your own workspace where you can generate text, images, and drafts for blog posts with your team. Tailor it to your needs and collaborate efficiently.",
      onClick: () => {
        setTab(0);
      },
    },
    {
      title: "Join Organisation",
      description:
        "Join existing groups to connect with like-minded creators. Access shared resources for generating text, images, and blog post drafts, and expand your creative horizons.",
      onClick: () => {
        setTab(1);
      },
    },
  ];

  const handleAccept = (inviteID, data) => {
    acceptTagoreInvitation(inviteID, data)
      .then(() => {
        successToast("Invitation accepted successfully");
        fetchInvitations();
        setTimeout(() => {
          if (process.env.NODE_ENV === "development") {
            window.location.href = "/.factly/tagore/web";
          } else {
            window.location.href = "/";
          }
        }, 1500);
      })
      .catch(() => {
        errorToast("Unable to accept invitation");
      });
  };

  const handleReject = async (inviteID) => {
    rejectInvitation(inviteID)
      .then(() => {
        successToast("Invitation rejected successfully");
        fetchInvitations();
      })
      .catch(() => {
        errorToast("Unable to reject invitation");
      });
  };

  const fetchInvitations = async () => {
    getInvitations()
      .then((res) => {
        setInvitations(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchInvitations();
  }, []);

  let tableColumns = [
    {
      title: "Organisation",
      dataIndex: "organisation",
      width: "w-1/4",
    },
    {
      title: "Invited By",
      dataIndex: "invited_by",
      width: "w-1/4",
    },
    {
      title: "Role",
      dataIndex: "role",
      width: "w-1/4",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      width: "w-1/4",
    },
  ];

  const tableStyles = {
    valuesPadding: "px-4 py-6",
    headerPadding: "p-4",
  };

  const [invitations, setInvitations] = useState([]);

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="flex flex-col w-2/5 gap-2 justify-center">
        <div className="w-full flex justify-center">
          <img src={tagoreAILogo} className="w-3/5" />
        </div>
        {tab === -1 && (
          <div className="flex justify-center gap-4 mt-4">
            {tabOptions.map((item, index) => {
              return (
                <div
                  key={index}
                  className="p-2 rounded-md border border-border-secondary cursor-pointer"
                  onClick={() => item.onClick()}
                >
                  <div className="flex flex-col gap-2">
                    <span className="text-xl font-[500]">{item.title}</span>
                    <span className="text-base">{item.description}</span>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {tab === 0 && (
          <div className="flex flex-col gap-4 mt-4">
            <h2 className="text-2xl font-bold">
              Get started by creating your Organisation
            </h2>
            <div className="flex flex-col gap-2">
              <Input
                label={"Name"}
                placeholder={"Enter your organisation name"}
                type={"input"}
                initialValue={form.name.value}
                error={form.name.error}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value) {
                    setForm((prev) => ({
                      ...prev,
                      name: {
                        value,
                        error: "",
                      },
                      slug: {
                        ...prev.slug,
                        value: maker(value),
                      },
                    }));
                  } else {
                    setForm((prev) => ({
                      ...prev,
                      name: {
                        value,
                        error: "Name cannot be empty",
                      },
                    }));
                  }
                }}
                required={true}
              ></Input>
              <Input
                label={"Slug"}
                placeholder={"Enter your organisation slug"}
                type={"input"}
                initialValue={form.slug.value}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value) {
                    setForm((prev) => ({
                      ...prev,
                      slug: {
                        value,
                        error: "",
                      },
                    }));
                  } else {
                    setForm((prev) => ({
                      ...prev,
                      slug: {
                        ...prev.slug,
                        value,
                        error: "Slug cannot be empty",
                      },
                    }));
                  }
                }}
                required={true}
                error={form.slug.error}
              ></Input>
              <Input
                label={"Description"}
                placeholder={"Describe your organisation"}
                type={"textarea"}
                initialValue={form.description.value}
                error={form.description.error}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value) {
                    setForm((prev) => ({
                      ...prev,
                      description: {
                        value,
                        error: "",
                      },
                    }));
                  } else {
                    setForm((prev) => ({
                      ...prev,
                      description: {
                        ...prev.description,
                        value,
                        error: "Description cannot be empty",
                      },
                    }));
                  }
                }}
              ></Input>
              <button
                className="text-white bg-black rounded-md py-2"
                onClick={handleFormSubmit}
              >
                {loading ? (
                  <ClipLoader color={"white"} loading={true} size={"16px"} />
                ) : (
                  "Create Organisation"
                )}
              </button>
              <button
                className="text-black rounded-md py-2"
                onClick={() => setTab(-1)}
              >
                Go Back
              </button>
            </div>
          </div>
        )}
        {tab === 1 && (
          <div className="flex flex-col gap-6">
            <span className="text-2xl text-[#1e1e1e] font-semibold">
              Invitations
            </span>
            <table className="border-collapse">
              <thead>
                {tableColumns.map((column, index) => (
                  <th
                    key={index}
                    className={`bg-background-sidebar text-sm font-medium rounded-t-md text-text-primary text-left p-4 ${column.width} ${tableStyles.headerPadding}`}
                  >
                    {column.title}
                  </th>
                ))}
              </thead>
              <tbody>
                {invitations?.map((invitation, index) => (
                  <tr
                    key={index}
                    className={`border-b border-[#eaeaea] ${
                      index % 2 === 0 ? "bg-white" : "bg-[#fafafa]"
                    }`}
                  >
                    <td
                      key={index}
                      className={`text-sm font-normal text-text-secondary ${tableColumns[index].width} ${tableStyles.valuesPadding}`}
                    >
                      {invitation.organisation?.title}
                    </td>
                    <td
                      key={index}
                      className={`text-sm font-normal text-text-secondary ${tableColumns[index].width} ${tableStyles.valuesPadding}`}
                    >
                      {invitation.invited_by?.display_name
                        ? invitation.invited_by?.display_name
                        : invitation.invited_by?.email}
                    </td>
                    <td
                      key={index}
                      className={`text-sm font-normal text-text-secondary ${tableColumns[index].width} ${tableStyles.valuesPadding}`}
                    >
                      {invitation.role}
                    </td>
                    <td
                      className={`flex items-center ${tableStyles.valuesPadding} gap-4 `}
                    >
                      <button
                        className={`p-2 rounded-md bg-border-secondary hover:bg-[#d0d1d6]`}
                        onClick={() =>
                          handleAccept(invitation.id, {
                            role: invitation.role,
                            organisation_id: invitation.organisation.id,
                            inviter_id: invitation.invited_by.id,
                            org_id: invitation.organisation.id,
                          })
                        }
                      >
                        Accept
                      </button>
                      <button
                        className={`p-2 rounded-md bg-border-secondary hover:bg-red-500 hover:text-white`}
                        onClick={() => handleReject(invitation.id)}
                      >
                        Remove
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <ToastContainer
        toastClassName={({ type }) =>
          type === "error"
            ? "w-[340px] border-l-[12px] border-[#DA3125] rounded-md shadow-lg bg-[#FFF]"
            : type === "success"
            ? "w-[340px] border-l-[12px] border-[#03C04A] rounded-md shadow-lg bg-[#FFF]"
            : type === "warning"
            ? "w-[340px] border-l-[12px] border-[#EA8700] rounded-md shadow-lg bg-[#FFF]"
            : ""
        }
        className="space-y-4  "
      />
    </div>
  );
}
