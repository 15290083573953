import React from "react";

const MenuIcon = () => {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6015_267)">
        <path
          d="M11.4199 31.749H36.9199V28.5824H11.4199V31.749ZM19.4199 23.8324H36.9199V20.6657H19.4199V23.8324ZM11.4199 12.749V15.9157H36.9199V12.749H11.4199Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_6015_267">
          <rect
            width="44"
            height="44"
            fill="white"
            transform="translate(0.669922 0.249023)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MenuIcon;
