import { useEffect, useRef, useState } from "react";

export const useSocket = (url, callBack) => {
  const socket = useRef(null);
  const [isConnected, setIsConnected] = useState(false);
  const isClosing = useRef(false);

  const connect = () => {
    if (url && (!socket.current || socket.current.url !== url)) {
      // Disconnect previous socket if it exists
      disconnect();

      // Check if previous connection is still closing
      if (isClosing.current) {
        setTimeout(connect, 1000); // try to connect again after a delay
        return;
      }

      // Create a new WebSocket connection
      socket.current = new WebSocket(url);

      // Set up the WebSocket connection callbacks on open
      socket.current.onopen = () => {
        setIsConnected(true);
      };

      // Set up the WebSocket connection callbacks on close
      socket.current.onclose = () => {
        setIsConnected(false);
        isClosing.current = false;
      };

      // Set up the WebSocket connection callbacks on message
      socket.current.onmessage = (event) => {
        callBack(event);
      };
    }
  };

  const disconnect = () => {
    if (socket.current) {
      socket.current.close();
      socket.current = null;
      setIsConnected(false);
      isClosing.current = true;
    }
  };

  useEffect(() => {
    if (url) {
      connect();
    }
    // Clean up the socket connection on unmount
    return () => {
      disconnect();
    };
  }, [url]);

  return {
    socket: socket.current,
    isConnected,
    connect,
    disconnect,
  };
};

export default useSocket;
