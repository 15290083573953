export function ChatContainer({ sidebarVisible, children }) {
  return (
    <div
      className={`${sidebarVisible ? "flex-[7]" : "w-full"} 
        h-full flex justify-between flex-col`}
    >
      {children}
    </div>
  );
}
