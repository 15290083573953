import { useEffect, useRef, useState } from "react";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../actions/kratos";
import { getOrganisationsFromKavach } from "../../actions/organisation";
import { getProfile } from "../../actions/profile";
import factlyLogo from "../../assets/FactlyLogo.png";
import { ReactComponent as ChatIcon } from "../../assets/icons/ChatTeardropText.svg";
import { ReactComponent as FileTextIcon } from "../../assets/icons/FileText.svg";
import { ReactComponent as ImageIcon } from "../../assets/icons/ImageSquare.svg";
import { ReactComponent as InfoQ } from "../../assets/icons/InfoQ.svg";
import { ReactComponent as PlusCircle } from "../../assets/icons/PlusCircle.svg";
import { ReactComponent as Setting } from "../../assets/icons/SettingsGear.svg";
import { ReactComponent as SignOut } from "../../assets/icons/SignOut.svg";
import { ReactComponent as PersonaIcon } from "../../assets/icons/Smiley.svg";
import { ReactComponent as SquareFour } from "../../assets/icons/SquaresFour.svg";
import { ReactComponent as WorkflowIcon } from "../../assets/icons/TreeStructure.svg";
import { ReactComponent as TagoreLogoDark } from "../../assets/icons/tagoreAiBlack.svg";
import useDarkMode from "../../hooks/useDarkMode";
import useOutsideClick from "../../hooks/useOutsideClick";
import useWindowSize from "../../hooks/useWindowSize";
import { getInitials } from "../../util/sluger";
import { errorToast } from "../../util/toasts";
import { Avatar } from "../avatar/avatar";
import { withOrg } from "../organisation/withOrg";
import { Question } from "@phosphor-icons/react";

//  getprofileName trims down the profile name and adds a ellipsis to the end of it if it's length is greater than MAX_PROFILE_NAME_LENGTH
const getProfileName = (name, profileData) => {
  const MAX_PROFILE_NAME_LENGTH = 15;
  const fullName = name
    ? `${name} ${profileData?.last_name ?? ""}`
    : profileData?.email;
  return fullName.length > MAX_PROFILE_NAME_LENGTH
    ? `${fullName.slice(0, MAX_PROFILE_NAME_LENGTH)}...`
    : fullName;
};

export const TopNav = withOrg(({ selectedOrg }) => {
  // open menu state
  const [openMenu, setOpenMenu] = useState(false);
  const navigate = useNavigate();
  let menuRef = useRef();
  let anchorRef = useRef();
  useOutsideClick(menuRef, anchorRef, () => setOpenMenu(false));
  const dispatch = useDispatch();
  const { organisation, organisationList, profileData } = useSelector(
    ({ organisations, profile }) => {
      const org = organisations?.details?.find(
        (org) => org?.id === selectedOrg
      );
      const profileData = profile?.details;

      return {
        organisation: org,
        organisationList: organisations?.details,
        profileData,
      };
    }
  );
  const orgList = organisationList?.map((org) => ({
    name: org?.title,
    icon: () => (
      <Avatar
        size="small"
        backgroundColor="bg-[#e93648]"
        data={org?.title ? getInitials(org?.title) : "--"}
        textColor="#ffffff"
      />
    ),
    onClick: () => {
      dispatch({ type: "SELECT_ORG", payload: org.id });
    },
  }));
  const manageProfileOptions = [
    ...orgList,
    // {
    //   name: "Add a Workspace",
    //   icon: () => <PlusCircle />,
    //   onClick: () => {
    //     navigate("/org/create");
    //   },
    // }, commenting out for now. will be added later.
  ];
  const handleLogout = () => {
    logout()
      .then((res) => {
        window.location.href = res.logout_url;
      })
      .catch(() => {
        errorToast("error logging out");
      });
  };

  return (
    <>
      <div className="flex gap-5 items-center relative">
        <Link to={window.REACT_APP_TAGORE_DOC_URL} target="_blank">
          <Question size={32} className="cursor-pointer" />
        </Link>
        <div
          ref={anchorRef}
          onClick={(e) => {
            e.stopPropagation();
            setOpenMenu((prevState) => !prevState);
          }}
          className={`flex p-1 gap-1 cursor-pointer rounded-lg ${
            openMenu ? "bg-primary-yellow" : ""
          }`}
        >
          <img
            src={organisation?.logo || factlyLogo}
            className="object-contain"
            alt="logo"
          />
          <Avatar
            size="small"
            textColor="#ffffff"
            backgroundColor={"bg-[#e93648]"}
            data={
              organisation?.title ? getInitials(organisation?.title)[0] : "--"
            }
          />
        </div>
        {openMenu && (
          <>
            <div
              ref={menuRef}
              className="menuContainer w-[260px] h-[368px] top-[75px] right-[-2px] absolute z-10 shadow rounded-2xl p-6 bg-[#FCFCFC]"
            >
              <div className="profileSection flex flex-col gap-5 w-52 h-44 relative bg-[#F9F9F9] rounded-lg">
                <div className="w-fit mx-auto">
                  <Avatar
                    size="small"
                    backgroundColor={"bg-[#e93648]"}
                    textColor="#ffffff"
                    data={
                      profileData.email ? getInitials(profileData.email) : "--"
                    }
                  />
                </div>
                <div className="text-center text-stone-900 text-sm font-medium leading-tight">
                  {profileData.first_name || profileData.email
                    ? getProfileName(profileData.first_name, profileData)
                    : "----"}
                </div>
                <div className="flex gap-2 items-center justify-center">
                  <div className="h-8 px-2 py-1.5  bg-neutral-50 rounded-3xl border border-zinc-100 justify-center items-center gap-1 inline-flex">
                    <Link
                      to={"/passwords"}
                      className="text-stone-500 text-md font-normal hover:text-blue-500 transition-colors duration-100"
                    >
                      Manage your account
                    </Link>
                  </div>
                  <div className="h-8 px-2 py-1.5  bg-red-50 rounded-full border border-red-100 justify-center items-center gap-1 inline-flex">
                    <SignOut
                      className="cursor-pointer"
                      onClick={handleLogout}
                    />
                  </div>
                </div>
              </div>

              <div className="w-52 h-[1px] bg-zinc-100 rounded-sm my-3" />
              <div className="workSpaceSection w-52 h-32 px-2 py-3 bg-[#F9F9F9] rounded-lg flex-col justify-start gap-5 inline-flex">
                <div className="text-stone-500 text-xs font-normal w-full text-center  leading-tight">
                  Workspaces
                </div>
                <div className="overflow-y-scroll h-32 space-y-5">
                  {manageProfileOptions.map((option, index) => (
                    <div onClick={option?.onClick} key={index}>
                      <li
                        className={`flex flex-row items-center gap-2 pl-0 p-2 rounded hover:bg-button-primary dark:hover:bg-background-sidebar-alt cursor-pointer dark:text-white`}
                        onClick={() => {
                          if (option?.onClick) {
                            option.onClick();
                          }
                        }}
                        style={{ position: "relative" }}
                      >
                        <option.icon />
                        <h3 className="text-base "> {option.name} </h3>
                        {index !== manageProfileOptions.length - 1 && (
                          <span className="border-b  w-16 left-12 bottom-[-10px] border-[#F0F0F0] absolute  cursor-none right-1"></span>
                        )}
                      </li>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
});

export function Sidebar({ sideBarOpen, setSidebarOpen, selectedOrg }) {
  const dispatch = useDispatch();
  // const [openMenu, setOpenMenu] = useState(false);
  // const [openProfileMenu, setOpenProfileMenu] = useState(false);
  // const [notificationBell, setNotificationBell] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const { isMobileScreen } = useWindowSize();
  // const { darkMode } = useDarkMode();

  const { organisation, organisationList, profileData } = useSelector(
    ({ organisations, profile }) => {
      const org = organisations?.details?.find(
        (org) => org?.id === selectedOrg
      );
      const profileData = profile?.details;

      return {
        organisation: org,
        organisationList: organisations?.details,
        profileData,
      };
    }
  );

  const menuOptions = [
    {
      name: "Dashboard",
      icon: SquareFour,
      linkTo: "/",
    },
    {
      name: "Documents",
      icon: FileTextIcon,
      linkTo: "/documents",
    },
    {
      name: "Images",
      icon: ImageIcon,
      linkTo: "/images",
    },
    {
      name: "Personas",
      icon: PersonaIcon,
      linkTo: "/personas",
    },
    {
      name: "Chat",
      icon: ChatIcon,
      linkTo: "/chats/dashboard",
    },
  ];
  let navigate = useNavigate();

  const orgList = organisationList?.map((org) => ({
    name: org?.title,
    icon: () => (
      <Avatar
        backgroundColor={"bg-[#e93648]"}
        data={org?.title ? getInitials(org?.title) : "--"}
        textColor="#ffffff"
      />
    ),
    onClick: () => {
      dispatch({ type: "SELECT_ORG", payload: org.id });
    },
  }));

  const profileOptions = [
    {
      name: "Settings",
      icon: Setting,
      onClick: () => {
        navigate("/org");
      },
    },
    {
      name: "Logout",
      icon: SignOut,
      onClick: () => {
        navigate("/org");
      },
    },
  ];

  const manageProfileOptions = [
    // {
    //   name: "Settings",
    //   icon: () => (
    //     <FiSettings
    //       size={styles.manageicons.fontSize}
    //       color={styles.icons.color}
    //       className="ml-1"
    //     />
    //   ),
    //   onClick: () => {
    //     navigate("/org");
    //   },
    // },
    // ...orgList,
    ...orgList,
    {
      name: "Add a Workspace",
      icon: () => <PlusCircle />,
      onClick: () => {
        navigate("/org/create");
      },
    },
  ];

  useEffect(() => {
    const path = window.location.pathname;

    let index = 0;
    if (process.env.NODE_ENV === "development") {
      // find index of the menu option based on the path
      menuOptions.forEach((option, currentIndex) => {
        if (`/.factly/tagore/web${option.linkTo}` === path) {
          index = currentIndex;
          return;
        }
      });
    } else {
      index = menuOptions.findIndex((option) => option.linkTo === path);
    }
    if (index > -1) {
      setActiveTab(index);
    } else {
      setActiveTab(0);
    }
  }, []);

  const fetchOrganisationsFromKavach = async () => {
    const response = await getOrganisationsFromKavach();
    dispatch({
      type: "ADD_ORGS",
      payload: response?.map((org) => ({
        ...org?.organisation,
        role: org?.permission?.role,
      })),
    });
  };

  useEffect(() => {
    fetchOrganisationsFromKavach();
  }, []);

  const fetchProfile = async () => {
    getProfile()
      .then((res) => {
        dispatch({
          type: "ADD_PROFILE",
          payload: res,
        });
      })
      .catch(() => {
        errorToast("Unable to fetch profile. Please try again later.");
      });
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <div
      className={`relative ${
        isMobileScreen
          ? sideBarOpen
            ? "w-[100vw] fixed right-0 top-0 z-50 flex flex-row-reverse duration-300"
            : "w-0"
          : "w-full min-w-[250px]"
      } `}
      key={"sidebar" + selectedOrg}
    >
      {(!isMobileScreen || sideBarOpen) && (
        <>
          <div
            className={`${
              isMobileScreen ? "w-[80vw] z-50" : "w-full"
            } p-5 pt-8 bg-background-sidebar h-screen flex flex-col dark:bg-background-sidebar-alt`}
          >
            {/* <div className={`flex gap-x-2 items-center justify-center w-full`}>
              <img
                src={logo}
                className="cursor-pointer w-full h-[48px]"
                alt="logo"
              />
            </div> */}
            <div className="flex flex-col  gap-2">
              <div className="relative ml-auto mr-auto">
                <TagoreLogoDark />
                {/* <button
                  className={`
                mb-2
                flex flex-row justify-between items-center
                w-full gap-x-2.5
                ${darkMode ? "bg-button-primary-alt" : "transparent"}
                ${openMenu ? "bg-primary-yellow" : ""}
                rounded-lg
                p-1.5
              `}
                  onClick={() => setOpenMenu((prevState) => !prevState)}
                >
                  <div className="flex flex-row gap-[6px] items-center">
                    <OrgAvatar
                      data={
                        organisation?.title
                          ? getInitials(organisation?.title)
                          : "--"
                      }
                    />
                    <span className="dark:text-white">
                      {organisation?.title
                        ? getOrgName(organisation?.title)
                        : "----"}
                    </span>
                  </div>
                </button> */}
                {/* {openMenu && (
                  <nav
                    className={`flex flex-col gap-2 bg-white dark:bg-background-secondary-alt p-2 rounded-lg mx-auto absolute z-[100] top-[50px] w-full `}
                  >
                    {manageProfileOptions.map((option, index) => (
                      <div onClick={option?.onClick} key={index}>
                        <li
                          className={`flex flex-row items-center gap-2 pl-0 p-2 rounded hover:bg-button-primary dark:hover:bg-background-sidebar-alt cursor-pointer dark:text-white`}
                          onClick={() => {
                            if (option?.onClick) {
                              option.onClick();
                            }
                          }}
                          style={{ position: "relative" }}
                        >
                          <option.icon />
                          <h3 className="text-base "> {option.name} </h3>
                          {index !== manageProfileOptions.length - 1 && (
                            <span
                              className="border-b border-[#D1D1D1] absolute bottom-[-5px] cursor-none left-[15px] right-1"
                              style={{ width: "80%" }}
                            ></span>
                          )}
                        </li>
                      </div>
                    ))}
                  </nav>
                )} */}
              </div>
            </div>
            {/* Sidebar menu options */}
            <nav className={`pt-6 flex-col items-center justify-center`}>
              {menuOptions.map((menu, index) => (
                <Link to={menu.linkTo} key={index}>
                  <li
                    key={index}
                    className={`text-base font-normal flex items-center justify-start p-2 cursor-pointer rounded-lg
                ${
                  activeTab !== index
                    ? "dark:hover:bg-button-primary-alt text-primary-grey hover:bg-primary-yellow"
                    : "dark:bg-button-primary-alt bg-primary-yellow text-primary-black"
                }
                mt-2 dark:text-white`}
                    onClick={() => setActiveTab(index)}
                  >
                    <div className="flex items-center gap-x-4">
                      <menu.icon
                        className={
                          activeTab !== index ? "fill-666666" : "fill-1e1e1e"
                        }
                      />
                      <h3>{menu.name}</h3>
                    </div>
                  </li>
                </Link>
              ))}
            </nav>
            {/* Menu list when they click on the manage profile button */}
            {/* <div className="relative mt-auto">
              {openProfileMenu && (
                <nav
                  className={`flex flex-col gap-2 bg-white dark:bg-background-secondary-alt p-4 rounded-lg mx-auto absolute z-[100] bottom-[60px] w-full `}
                >
                  {profileOptions.map((option, index) => (
                    <div onClick={option?.onClick} key={index}>
                      <li
                        className={`flex flex-row items-center gap-2 pl-0 p-2 rounded hover:bg-button-primary dark:hover:bg-background-sidebar-alt cursor-pointer dark:text-white`}
                        onClick={() => {
                          if (option?.onClick) {
                            option.onClick();
                          }
                        }}
                        style={{ position: "relative" }}
                      >
                        <h3 className="text-base mr-auto"> {option.name} </h3>
                        <option.icon />

                        {index !== profileOptions.length - 1 && (
                          <span
                            className="border-b border-[#D1D1D1] absolute bottom-[-5px] cursor-none"
                            style={{ width: "95%" }}
                          ></span>
                        )}
                      </li>
                    </div>
                  ))}
                </nav>
              )}
              <button
                className={`mb-2
                flex flex-row justify-between items-center
                 gap-x-2.5
                ${darkMode ? "bg-button-primary-alt" : "transparent"}
                ${openProfileMenu ? "bg-primary-yellow w-[85%]" : "w-full"}
                rounded-lg
                p-1.5
              `}
                onClick={() => setOpenProfileMenu((prevState) => !prevState)}
              >
                <div className="flex flex-row gap-[6px] items-center">
                  <OrgAvatar
                    data={
                      profileData.first_name || profileData.email
                        ? getInitials(profileData.first_name)
                        : "--"
                    }
                  />
                  <span className="dark:text-white">
                    {profileData.first_name || profileData.email
                      ? getProfileName(profileData.first_name,profileData)
                      : "----"}
                  </span>
                </div>
                {notificationBell ? (
                  <DarkBell className="absolute right-0" />
                ) : (
                  <Bell className="absolute right-0" />
                )}
              </button>
            </div> */}
          </div>
          {isMobileScreen && (
            <div className="z-40 w-full h-screen fixed inset-0 bg-black bg-opacity-50">
              <AiOutlineMenuUnfold
                className="w-6 h-6 m-4 ml-8 text-white"
                onClick={() => setSidebarOpen(false)}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Sidebar;
