import {
  Export,
  GearSix,
  PlusCircle,
  Question,
  SidebarSimple,
} from "@phosphor-icons/react";
import { theme } from "../../commons/styles";
import { ButtonWithIcon } from "../buttons/buttonWithIcon";

import { Link } from "react-router-dom";
import { ConnectedClip, MaxMessageClip } from "../clip";

// gpt4 = "gpt4"
// gpt4_turbo = "gpt4_turbo"
// gpt35turbo = "gpt35turbo"
// gemini_pro = "gemini_pro"
// claude2 = "claude2"
const getModelLabel = (model) => {
  switch (model) {
    case "gpt4":
      return "GPT-4";
    case "gpt35turbo":
      return "GPT-3.5-turbo";
    case "gpt4_turbo":
      return "GPT-4 Turbo";
    case "gemini_pro":
      return "Gemini Pro";
    case "claude2":
      return "Claude 2";
    default:
      return "GPT-4";
  }
};

export const ChatNavbar = ({
  sidebarVisible,
  handleNewChat,
  chat,
  toggleSidebar,
  showSettings,
  setShowSettings,
  modelName,
  handleShareLink,
  showShare = true,
  showSettingsIcon = true,
  persona,
  isPersona = false,
  persona_description = "",
  handlePersonaModel = () => {},
  showLimit = false,
  limit = 0,
  isConnected,
}) => {
  return (
    <div
      className={`flex flex-1 border-b border-b-primary-border items-center px-10 relative ${
        (!showSettingsIcon && !showShare && !sidebarVisible) || isPersona
          ? "justify-start"
          : "justify-center"
      }`}
    >
      {!sidebarVisible && (
        <div className={`flex h-3/5 items-center`}>
          <ButtonWithIcon
            icon={<PlusCircle size={theme.iconSize.medium} color="#fff" />}
            isPrimary={true}
            onClick={() => handleNewChat()}
            disabled={chat?.messages?.length === 0}
          >
            New Chat
          </ButtonWithIcon>
          <div className="p-3">
            <SidebarSimple
              weight="duotone"
              className={`text-black border ${
                !sidebarVisible ? "rounded-md" : "rounded-full"
              } p-1`}
              size={theme.iconSize.large}
              cursor={"pointer"}
              onClick={() => toggleSidebar()}
            />
          </div>
        </div>
      )}
      {showSettingsIcon && (
        <div
          className={`flex items-center gap-2 absolute ml-auto mr-auto ${
            showSettings && "bg-[#F3EED9] p-2 rounded-md"
          }`}
          onClick={() => setShowSettings((prev) => !prev)}
        >
          <span className="text-[#798897] cursor-pointer">
            Model: {modelName}
          </span>
          <GearSix
            size={theme.iconSize.medium}
            color="#798897"
            cursor={"pointer"}
          />
        </div>
      )}
      {persona && isPersona && (
        <div className="flex gap-2 items-center justify-between w-full">
          {/* persona details */}
          <div className="flex flex-col gap-2 items-start w-1/2 p-2">
            <span className="text-lg font-semibold text-gray-800">
              {persona}
            </span>
            <span className="text-sm text-gray-600">{persona_description}</span>
          </div>
          <div className="flex items-center gap-2">
            {showLimit && <MaxMessageClip msgCount={limit} />}
            <ConnectedClip connected={isConnected} />
            <Link
              to={window.REACT_APP_PERSONA_FEEDBACK_URL}
              className="text-blue-500 cursor-pointer"
              target="_blank"
            >
              Send Feedback
            </Link>
            <Link to={window.REACT_APP_TAGORE_DOC_URL} target="_blank">
              <Question
                size={theme.iconSize.medium}
                color="#798897"
                cursor={"pointer"}
              />
            </Link>
            <Export
              size={theme.iconSize.medium}
              color="#666"
              onClick={() => handleShareLink()}
              cursor={"pointer"}
            />
          </div>
        </div>
      )}
      {showShare && (
        <Export
          size={theme.iconSize.large}
          color="#666"
          className="ml-auto"
          onClick={() => handleShareLink()}
          cursor={"pointer"}
        />
      )}
    </div>
  );
};
