import Select from "react-select";

export const ChatSettingsForm = ({ formValues, setFormValues }) => {
  const styles = {
    row: "flex flex-row justify-between gap-4 w-full p-4",
    input: "flex-1 rounded-sm border border-[#D1D1D1] cursor-pointer",
    label: "flex-1",
  };

  const models = [
    {
      label: "GPT-3.5 Turbo",
      value: "gpt-3.5-turbo",
    },
    {
      label: "LLAMA 2",
      value: "meta-llama/Llama-2-70b-chat-hf",
    },
    {
      label: "Google's Gemini Pro",
      value: "gemini-pro",
    },
    {
      label: "Claude 2",
      value: "claude-2",
    },
  ];

  const conversationStylevalues = {
    Creative: 0.9,
    Neutral: 0.5,
    Precise: 0.1,
  };

  const conversationStyles = [
    {
      heading: "Creative",
      description:
        "This style offers imaginative and inventive responses, often using wordplay and metaphors, making it ideal for brainstorming and storytelling.",
    },
    {
      heading: "Neutral",
      description:
        "Characterized by a balanced and objective tone, this style provides factual and unbiased information, suitable for objective discussions and fact-based queries.",
    },
    {
      heading: "Precise",
      description:
        "Known for its accuracy and attention to detail, this style offers clear and specific information, making it ideal for technical explanations, instructions, and conveying facts and figures.",
    },
  ];

  return (
    <div className="flex flex-col gap-4 w-3/5">
      <div className={styles.row}>
        <span className={styles.label}>Choose a language model</span>
        <Select
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: "black",
              primary25: "#EAEAEA",
              primary50: "#EAEAEA",
              primary75: "#EAEAEA",
            },
          })}
          value={formValues.model}
          defaultValue={formValues.model}
          onChange={(newValue) => {
            setFormValues({ ...formValues, model: newValue });
          }}
          className={styles.input}
          options={models}
        ></Select>
      </div>
      <div className={styles.row}>
        <span className={styles.label}>Use a system prompt</span>
        <div className="flex-1 border border-[#f0f0f0] rounded-md h-30">
          <textarea
            className={"resize-none p-2 outline-none w-full"}
            onChange={(e) => {
              setFormValues({ ...formValues, system_prompt: e.target.value });
            }}
            placeholder="Enter your system prompt here"
          ></textarea>
        </div>
      </div>
      <div className={styles.row}>
        <span className={styles.label}>Choose a conversation style</span>
        <div className={`flex-1 flex flex-col w-full gap-2`}>
          {conversationStyles.map((conversationStyle) => {
            return (
              <div className="border border-[#f0f0f0] p-[10px] w-full flex flex-col gap-2 rounded-lg">
                <div className="flex gap-2 items-center">
                  <input
                    type="checkbox"
                    name="conversation-style"
                    value={conversationStylevalues[conversationStyle.heading]}
                    checked={
                      formValues.temperature ===
                      conversationStylevalues[conversationStyle.heading]
                    }
                    onClick={() => {
                      setFormValues({
                        ...formValues,
                        temperature:
                          conversationStylevalues[conversationStyle.heading],
                      });
                    }}
                  />
                  <span className="text-[#1e1e1e] text-sm font-medium">
                    {conversationStyle.heading}
                  </span>
                </div>
                <span className="text-xs text-[#666]">
                  {conversationStyle.description}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
