import React, { useState } from "react";
import { RiShareBoxLine } from "react-icons/ri";
import UppyUploader from "../../components/Uppy";
import Modal from "../../components/Modal";
import GenerateModal from "./GenerateModal";
import { AiOutlineDelete } from "react-icons/ai";
import { getURL } from "../../util/validateURL";
import Select from "react-select";
import { DropdownIndicator } from "../documents/create";
import { ReactComponent as CaretCircleLeft } from "../../assets/icons/CaretCircleLeft.svg";
import { useNavigate } from "react-router-dom";

function Link({ children, className, onDelete, onClick, showDelete = true }) {
  return (
    <div className="flex items-center">
      <span className={className} onClick={onClick}>
        {children}
      </span>
      {showDelete && (
        <AiOutlineDelete
          cursor={"pointer"}
          onClick={onDelete}
          className="ml-2 text-red-500"
        />
      )}
    </div>
  );
}
function PersonaForm({
  handleSubmit,
  isEditForm,
  handleChange,
  requestBody,
  setOpen,
  open,
  handleUpload,
  onDelete,
  handleModalClose,
  genOpen,
  setGenOpen,
}) {
  const promptExampleLink = "https://poe.com/prompt_examples";
  const navigate = useNavigate();
  const [isImageVisible, setIsImageVisible] = useState(false);
  const chatTypes = [
    {
      value: "private",
      label: "Private: Only you can chat",
    },
    {
      value: "public",
      label: "Public: Anyone can chat",
    },
  ];
  const getChatType = (value) => {
    return chatTypes.find((chatType) => chatType.value === value);
  };
  return (
    // below is a controlled form whose values are stored in requestBody
    <form onSubmit={handleSubmit}>
      <div className="space-y-6 dark:text-white bg-white">
        <div className="flex flex-row justify-between items-center p-10 border-b border-primary-border">
          <BackToPersonas handleGoBack={() => navigate("/personas")} />
          <h1 className="text-center text-stone-900 text-base font-medium leading-7">
            {" "}
            {isEditForm ? "Edit " : "Create "} Persona
          </h1>
          <button
            type="submit"
            className=" text-white text-base font-normal  bg-primary-grey-blue  px-3 py-2 rounded-lg"
            onClick={handleSubmit}
          >
            {isEditForm ? "Save " : "Create "}
            and chat
          </button>
        </div>
        <div className="w-full">
          <div className="grid 2sm:grid-cols-[1fr_1fr] grid-cols-1 mx-[15%] gap-y-6 gap-x-12 ">
            <div className="flex flex-col gap-4 2sm:pt-6 pb-8 pt-6 ">
              <h1 className="text-stone-900 text-sm font-normal">Name</h1>
              <p className="text-[12px] font-normal text-[#6C6C6C] dark:text-gray-100">
                Give your persona a name that you can easily remember
              </p>
            </div>
            <div className="w-full flex flex-col  pb-8 gap-2 pt-6 ">
              <input
                className=" w-full px-[18px] py-5 max-h-[55px] text-sm font-normal border border-[#D0D5DD] rounded-lg dark:bg-background-sidebar-alt dark:text-white dark:border-[#3b3b3b]"
                placeholder="Enter name here"
                name="name"
                onChange={handleChange}
                value={requestBody.name.value}
              />
              {/* error text which should be red danger*/}
              {requestBody.name.error && (
                <span className="text-[12px] px-4 font-normal text-[#ed4b48]">
                  {requestBody.name.error}
                </span>
              )}
            </div>
            <div className="flex flex-col gap-4 2sm:pt-6  pb-8 pt-6 ">
              <h1 className="text-stone-900 text-sm font-normal">
                Description
              </h1>
              <p className="text-[12px] font-normal text-[#6C6C6C] dark:text-gray-100">
                Describe your persona in a few words so that you can easily find
                it later
              </p>
            </div>
            <div className="w-full flex flex-col  pb-8 gap-2 pt-6 ">
              <textarea
                className=" w-full px-4 py-3 border border-[#D0D5DD] rounded-lg dark:bg-background-sidebar-alt dark:text-white dark:border-[#3b3b3b] resize-none"
                rows={3}
                placeholder="Enter description here"
                name="description"
                onChange={handleChange}
                value={requestBody.description.value}
              />
              {/* error text which should be red danger*/}
              {requestBody.description.error && (
                <span className="text-[12px] px-4 font-normal text-[#ed4b48]">
                  {requestBody.description.error}
                </span>
              )}
            </div>
            <div className="flex flex-col  pb-8 gap-4 2sm:pt-6  pt-6 ">
              <h1 className="text-stone-900 text-sm font-normal">Prompt</h1>
              <p className="text-[12px] font-normal text-[#6C6C6C] dark:text-gray-100 w-[80%]">
                All conversations with this bot will start with your prompt but
                it will not be visible to the user in the chat. If you would
                like the prompt message to be visible to the user, consider
                using an intro message instead.{" "}
                <a
                  href={promptExampleLink}
                  className="text-stone-900 inline text-xs font-normal underline"
                >
                  Learn more
                </a>
              </p>
            </div>
            <div className="w-full flex  pb-8 flex-col gap-2 pt-6">
              <textarea
                className=" w-full px-4 py-3 border border-[#D0D5DD] rounded-lg dark:bg-background-sidebar-alt dark:text-white dark:border-[#3b3b3b] resize-none"
                rows={3}
                placeholder="Enter prompt here"
                name="prompt"
                onChange={handleChange}
                value={requestBody.prompt.value}
              />
              {/* error text which should be red danger*/}
              {requestBody.prompt.error && (
                <span className="text-[12px] px-4 font-normal text-[#ed4b48]">
                  {requestBody.prompt.error}
                </span>
              )}
            </div>
            <div className="flex  pb-8 flex-col gap-4 2sm:pt-6 pt-6-[#EAECF0]">
              <h1 className="text-stone-900 text-sm font-normal">Visibility</h1>
              <p className="text-[12px] font-normal text-[#6C6C6C] dark:text-gray-100">
                Choose who can see this persona
              </p>
            </div>
            <div className="pt-6 pb-8">
              <Select
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator,
                }}
                styles={{
                  control: (style) => {
                    return {
                      ...style,
                      maxHeight: "55px",
                      borderRadius: "10px",
                      border: "1px solid #D1D1D1",
                      background: "#FFF",
                      padding: "8px 14px",
                      boxShadow: "none",
                      "&:hover": {
                        border: "1px solid #D1D1D1",
                      },
                    };
                  },
                  dropdownIndicator: (style) => {
                    return {
                      ...style,
                      paddingRight: "0px",
                    };
                  },
                  valueContainer: (style) => {
                    return {
                      ...style,
                      paddingLeft: "0px",
                    };
                  },
                  placeholder: (style) => {
                    return {
                      ...style,
                      fontWeight: "normal",
                      color: "#A0AEC0",
                    };
                  },
                }}
                name="visibility"
                onChange={(newValue) => {
                  handleChange({
                    target: { name: "visibility", value: newValue },
                  });
                }}
                value={getChatType(requestBody.visibility.value)}
                defaultValue={
                  getChatType(requestBody.visibility.value) || chatTypes[1]
                }
                options={chatTypes}
              />
              {requestBody.visibility.error && (
                <span className="text-[12px] block pt-2 px-4 font-normal text-[#ed4b48]">
                  {requestBody.visibility.error}
                </span>
              )}
            </div>
            <div className="flex flex-col gap-4 2sm:pt-6  pb-8 pt-6 ">
              <h1 className="text-stone-900 text-sm font-normal">Avatar</h1>
              <p className="text-[12px] font-normal text-[#6C6C6C] dark:text-gray-100">
                Upload an image to represent your persona
              </p>
            </div>
            <div className="w-full flex gap-3 items-center pt-6  pb-8 ">
              {isImageVisible ? (
                <div className="flex items-center gap-4">
                  <img
                    src={getURL(requestBody.avatar.value.url)}
                    alt="avatar"
                    className="w-[100px] h-[100px] rounded-full"
                  />
                  <Link
                    onDelete={onDelete}
                    className="text-blue-500 cursor-pointer"
                    onClick={(e) => {
                      setIsImageVisible(false);
                      setGenOpen(true);
                    }}
                    showDelete={false}
                  >
                    Change Avatar
                  </Link>
                </div>
              ) : (
                <>
                  {!requestBody.avatar.value ? (
                    <div className="flex gap-5 items-center sm:flex-row flex-col sm:justify-between justify-center w-full">
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setGenOpen(true);
                        }}
                        className="flex w-48 h-12 px-3 py-2 bg-[#676767] rounded-lg justify-center items-center
                         text-center text-white text-base font-normal
                         dark:text-white dark:bg-background-sidebar-alt dark:hover:bg-white dark:hover:text-[#1e1e1e] dark:hover:border-[#1e1e1e] dark:hover:shadow-lg dark:border-[#3b3b3b] 
                         cursor-pointer"
                      >
                        Generate Avatar
                      </button>
                      {requestBody.avatar.error && (
                        <span className="text-[12px] px-4 font-normal text-[#ed4b48]">
                          {requestBody.avatar.error}
                        </span>
                      )}
                    </div>
                  ) : (
                    <Link
                      onDelete={onDelete}
                      className="text-blue-500 cursor-pointer"
                      onClick={(e) => setIsImageVisible(true)}
                    >
                      View Avatar
                    </Link>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="grid-cols-2 my-10"></div>
        <Modal open={open} onClose={handleModalClose}>
          <UppyUploader onUpload={handleUpload}></UppyUploader>
        </Modal>
        <GenerateModal
          open={genOpen}
          onClose={handleModalClose}
          initialPrompt={requestBody?.prompt?.value}
          handleSelectPersona={(avatar) => {
            handleChange({
              target: {
                name: "avatar",
                value: avatar,
              },
            });
          }}
        />
      </div>
    </form>
  );
}
const BackToPersonas = ({ handleGoBack }) => (
  <div className="flex items-center text-base font-medium text-primary-grey">
    <div
      onClick={handleGoBack}
      className="inline-flex items-center justify-center cursor-pointer whitespace-nowrap"
    >
      <CaretCircleLeft className="mr-2" />
      Back to personas
    </div>
  </div>
);

export default PersonaForm;
