import { HashLoader } from "react-spinners";
import { theme } from "../../commons/styles";

export default function CentralLoading({
  message = "Connecting to the Server... Please wait.",
}) {
  return (
    <div className="w-screen h-screen flex justify-center items-center flex-col gap-4">
      <HashLoader color="#000" size={theme.iconSize.large} />
      <span>{message}</span>
    </div>
  );
}
