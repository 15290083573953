import { CaretCircleLeft } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { AiFillTool, AiOutlineTool } from "react-icons/ai";
import { BiLogOutCircle } from "react-icons/bi";
import {
  BsBarChartLine,
  BsBarChartLineFill,
  BsEnvelopeOpen,
  BsEnvelopeOpenFill,
} from "react-icons/bs";
import { FaIdCard, FaRegIdCard } from "react-icons/fa";
import { GiSettingsKnobs } from "react-icons/gi";
import { HiMiniLockClosed, HiOutlineLockClosed } from "react-icons/hi2";
import {
  PiBellRingingFill,
  PiBellRingingThin,
  PiUsers,
  PiUsersFill,
} from "react-icons/pi";
import { VscSettings } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../actions/kratos";
import { getOrganisationsFromKavach } from "../../actions/organisation";
import { getProfile } from "../../actions/profile";
import useDarkMode from "../../hooks/useDarkMode";
import { errorToast } from "../../util/toasts";

export function SidebarAlt() {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [colorTab, setColorTab] = useState(0);
  const { darkMode } = useDarkMode();

  const [selectedTab, setSelectedTab] = useState({
    index: 0,
    section: "Organisation",
  });

  const { isAdmin } = useSelector(({ organisations }) => {
    let isAdmin =
      organisations?.details?.find(
        (organisation) => organisation?.id === organisations?.selectedOrg
      )?.role === "owner";
    return {
      isAdmin,
    };
  });
  const isOrganisationTabVisible = (menuOption, isAdmin) => {
    return menuOption.title !== "Organisation" || isAdmin;
  };

  const menuOptions = [
    {
      title: "Account",
      options: [
        {
          name: "Authentication",
          icon: ({ selected, ...props }) =>
            selected ? (
              <HiMiniLockClosed {...props} />
            ) : (
              <HiOutlineLockClosed {...props} />
            ),
          icon: ({ selected, ...props }) =>
            selected ? (
              <HiMiniLockClosed {...props} />
            ) : (
              <HiOutlineLockClosed {...props} />
            ),
          linkTo: "/passwords",
        },
        {
          name: "Invitations",
          icon: ({ selected, ...props }) =>
            selected ? (
              <BsEnvelopeOpenFill {...props} />
            ) : (
              <BsEnvelopeOpen {...props} />
            ),
          icon: ({ selected, ...props }) =>
            selected ? (
              <BsEnvelopeOpenFill {...props} />
            ) : (
              <BsEnvelopeOpen {...props} />
            ),
          linkTo: "/profile/invitations",
        },
      ],
    },
    {
      title: "Organisation",
      options: [
        {
          name: "Settings",
          icon: ({ selected, ...props }) =>
            selected ? <AiFillTool {...props} /> : <AiOutlineTool {...props} />,
          linkTo: "/org",
        },
        {
          name: "Usage",
          icon: ({ selected, ...props }) =>
            selected ? (
              <BsBarChartLineFill {...props} />
            ) : (
              <BsBarChartLine {...props} />
            ),
          linkTo: "/usage",
        },
        {
          name: "Members",
          icon: ({ selected, ...props }) =>
            selected ? <PiUsersFill {...props} /> : <PiUsers {...props} />,
          icon: ({ selected, ...props }) =>
            selected ? <PiUsersFill {...props} /> : <PiUsers {...props} />,
          linkTo: "/org/members",
        },
      ],
    },
  ].filter((menuOption) => isOrganisationTabVisible(menuOption, isAdmin));

  const handleLogout = () => {
    logout()
      .then((res) => {
        window.location.href = res.logout_url;
      })
      .catch(() => {
        errorToast("error logging out");
      });
  };

  const footerOptions = [
    {
      name: "Log out",
      Icon: BiLogOutCircle,
      onClick: handleLogout,
    },
  ];

  const style = {
    color: "#6e6e81",
    fontSize: "28px",
    selectedColor: "#666666",
  };

  const fetchOrganisationsFromKavach = async () => {
    const response = await getOrganisationsFromKavach();
    dispatch({
      type: "ADD_ORGS",
      payload: response?.map((org) => ({
        ...org?.organisation,
        role: org?.permission?.role,
      })),
    });
  };

  useEffect(() => {
    fetchOrganisationsFromKavach();
  }, []);

  const fetchProfile = async () => {
    getProfile()
      .then((res) => {
        dispatch({
          type: "ADD_PROFILE",
          payload: res,
        });
      })
      .catch(() => {
        errorToast("Unable to fetch profile. Please try again later.");
      });
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <div
      className={`flex flex-col p-4 pt-8 w-1/6 h-full ${
        darkMode
          ? "bg-background-sidebar-alt"
          : "bg-[#F9F9F9] border-r border-[#D1D1D1]"
      } justify-between`}
    >
      <div>
        <div className="flex items-center p-2 justify-center gap-2 cursor-pointer">
          <CaretCircleLeft
            size={style.fontSize}
            color={"#798897"}
            weight="fill"
          />
          <span
            className="text-[#798897]"
            onClick={() => {
              navigate("/");
            }}
          >
            Back to dashboard
          </span>
        </div>

        <ul
          className={`flex flex-col gap-4 p-0 m-0 pt-6 items-center justify-center mt-6 w-[100%]`}
        >
          {menuOptions.map((menuOption, mIndex) => {
            return (
              <div className="flex flex-col gap-1 " key={mIndex}>
                <span className="pl-2 font-medium text-[#999]">
                  {menuOption.title}
                </span>
                <div>
                  {menuOption.options.map((menu, index) => {
                    const isSelected =
                      selectedTab.section === menuOption.title &&
                      selectedTab.index === index;
                    return (
                      <Link to={menu.linkTo} key={index}>
                        <li
                          key={index}
                          className={`flex flex-col justify-between text-base font-normal text-[#1E1E1E] items-center px-4 py-2 cursor-pointer rounded-lg
                          ${isSelected && "bg-[#F3EED9]"}
                          ${colorTab !== index && "hover:bg-[#F3EED9]"}

                          ${
                            colorTab === index &&
                            (darkMode
                              ? "bg-button-primary-alt"
                              : "hover:bg-[#F3EED9]")
                          }
    
                          mt-2 ${
                            darkMode && "text-white hover:bg-button-primary-alt"
                          }`}
                          onClick={() => {
                            setSelectedTab({
                              index: index,
                              section: menuOption.title,
                            });
                          }}
                        >
                          <div className="flex justify-between w-[12vw] ">
                            <div className="flex gap-x-4 justify-start items-center">
                              <menu.icon
                                selected={isSelected}
                                size={style.fontSize}
                                color={
                                  isSelected ? style.selectedColor : style.color
                                }
                              />
                              <h3
                                className={`${
                                  isSelected ? "text-black" : "text-[#6e6e81]"
                                } text-base`}
                              >
                                {menu.name}
                              </h3>
                            </div>
                          </div>
                        </li>
                      </Link>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </ul>
      </div>
      <div>
        {footerOptions.map((option, index) => (
          <div
            className={`mb-2 flex flex-row gap-2 items-center justify-center w-full gap-x-2.5 ${
              darkMode && "bg-button-primary-alt"
            } rounded p-3 dark:text-white cursor-pointer`}
            onClick={option.onClick}
          >
            <option.Icon size={"16px"} />
            <span className="text-base">{option.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SidebarAlt;
