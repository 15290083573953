import { useEffect, useState } from "react";
import { CreateButton } from "../../components/buttons/CreateButton";
import PersonaCard from "../../components/cards/personaCard";
import Search, { ExpandableSearch } from "../../components/search";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "./pagination";
import { getPersona } from "../../actions/persona";
import { errorToast, successToast } from "../../util/toasts";
import useWindowSize from "../../hooks/useWindowSize";
import { deletePersonaById } from "../../actions/persona";
import { getURL } from "../../util/validateURL";
import { TopNav } from "../../components/layout/sidebar";

import BudgetSpeak from "../../assets/persona/BudgetSpeak.jpg";
import Parlens from "../../assets/persona/Parlens.jpg";
import PIB from "../../assets/persona/PIB.jpg";
import SachFactCheck from "../../assets/persona/SachFactCheck.jpg";
import BallotVerse from "../../assets/persona/BallotVerse.jpg";
import Trade from "../../assets/persona/Trade.jpg";
import CSR from "../../assets/persona/CSR.jpg";

import { useSelector } from "react-redux";
import { usePostHog } from "posthog-js/react";
import { dispatchPosthog } from "../../util/posthog";

export default function Personas() {
  const posthog = usePostHog();

  const navigate = useNavigate();

  const [tab, setTab] = useState("Factly");

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 8,
    search_query: "",
    count: 0,
  });

  const [searchQuery, setSearchQuery] = useState("");
  // personData is the data of the persona on the current page
  const [personaData, setPersonaData] = useState([]);

  const { organisations, profile } = useSelector(
    ({ organisations, profile }) => {
      return {
        organisations,
        profile,
      };
    }
  );

  const factlyPersona = [
    {
      key: "sach",
      image: SachFactCheck,
      name: "SACH Fact Check",
      desc: "Empowering Users to Navigate Fact and Fiction. Your Gateway to Verified Information and Debunked Hoaxes from Claim Review.",
      id: "-1",
      is_default: true,
      isFactly: true,
      onclick: () => {
        navigate("/personas/factly?persona_id=sach");

        dispatchPosthog(posthog, "Sach Persona Clicked", {});
      },
    },
    {
      key: "parlens",
      image: Parlens,
      name: "Parlens",
      desc: "Engage with the wealth of Lok Sabha and Rajya Sabha data effortlessly with Parlens, your conversational guide to parliamentary knowledge.",
      id: "-2",
      is_default: true,
      isFactly: true,
      onclick: () => {
        navigate("/personas/factly?persona_id=parlens");

        dispatchPosthog(posthog, "Parlens Persona Clicked", {});
      },
    },
    {
      key: "pib",
      image: PIB,
      name: "PIB Chronicle",
      desc: "Journey through the present and past with PIB Chronicle, your gateway to daily and historical insights from the Government of India's press releases.",
      id: "-5",
      is_default: true,
      isFactly: true,
      onclick: () => {
        navigate("/personas/factly?persona_id=pib");

        dispatchPosthog(posthog, "PIB Persona Clicked", {});
      },
    },
    {
      key: "budgetspeech",
      image: BudgetSpeak,
      name: "BudgetSpeak",
      desc: "Delve into the heart of India's financial decisions with BudgetSpeak, where budget speeches come alive through insightful conversations.",
      id: "-3",
      is_default: true,
      isFactly: true,
      onclick: () => {
        navigate("/personas/factly?persona_id=budgetspeech");

        dispatchPosthog(posthog, "BudgetSpeak Persona Clicked", {});
      },
    },
  ];

  const openAIAsst = [
    {
      key: "lse",
      image: BallotVerse,
      name: "BallotVerse India",
      desc: "Embark on a dialogue with the pulse of democracy – BallotVerse, decoding Lok Sabha Elections data through engaging conversations.",
      id: "-4",
      is_default: true,
      isFactly: true,
      onclick: () => {
        navigate("/assistants/chat?asst_id=lse");

        dispatchPosthog(posthog, "Ballotverse Persona Clicked", {});
      },
    },
    {
      key: "tt",
      image: Trade,
      name: "TradeTracer",
      desc: "Empowering decisions with TradeTracer, where expertise meets comprehensive trade data, illuminating India's export and import landscape.",
      id: "-6",
      is_default: true,
      isFactly: true,
      onclick: () => {
        navigate("/assistants/chat?asst_id=tt");

        dispatchPosthog(posthog, "Tradetracer Persona Clicked", {});
      },
    },
    {
      key: "csr",
      image: CSR,
      name: "CSR Explorer",
      desc: "Explore the social footprint of businesses with CSR Explorer, your guide to comprehensive corporate social responsibility data and insights.",
      id: "-7",
      is_default: true,
      isFactly: true,
      onclick: () => {
        navigate("/assistants/chat?asst_id=csr");

        dispatchPosthog(posthog, "CSR Persona Clicked", {});
      },
    },
  ];

  useEffect(() => {
    getPersona({
      page: pagination.page,
      limit: pagination.limit,
      search_query: pagination.search_query,
    })
      .then((data) => {
        setPersonaData(data.personas);
      })
      .catch((err) => {
        errorToast("Something went wrong! Please try again later.");
      });
  }, [pagination]);

  useEffect(() => {
    getPersona({
      page: pagination.page,
      limit: pagination.limit,
      search_query: pagination.search_query,
    }).then((data) => {
      setPersonaData(data.personas);
    });
  }, [pagination.search_query]);

  useEffect(() => {
    let timer = setTimeout(() => {
      setPagination({
        ...pagination,
        search_query: searchQuery,
        page: 1,
      });
    }, 500);
    return () => clearTimeout(timer);
  }, [searchQuery]);

  const { isMobileScreen } = useWindowSize();

  const handleDelete = (id) => {
    if (!id || id === "") return;

    deletePersonaById(id)
      .then((response) => {
        const newPersonaData = personaData.filter((persona) => {
          return persona.id !== id;
        });
        setPersonaData(newPersonaData);
        successToast("Persona deleted successfully");
      })
      .catch((err) => {
        errorToast("Error deleting persona");
      });
  };

  const isRenderPersona = (persona) => {
    if (tab == "All") return true;
    if (tab == "Factly") return persona?.isFactly;
    return !persona?.isFactly && !persona?.is_default;
  };

  return (
    <>
      <div className="flex pl-10 pr-9 py-7 flex-row items-center  gap-5">
        <Link to="/personas/create">
          <CreateButton
            text={isMobileScreen ? "" : "Create Personas"}
            icon={false}
          />
        </Link>
        {window.innerWidth >= 900 && (
          <ExpandableSearch
            width="auto"
            placeholder={"search Personas"}
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
            handleSearch={() => {
              setPagination({
                ...pagination,
                search_query: searchQuery,
              });
            }}
          />
        )}
        <div className="ml-auto">
          <TopNav />
        </div>
        {window.innerWidth < 900 && (
          <div>
            <Search
              placeholder={"search Personas"}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
              handleSearch={() => {
                setPagination({
                  ...pagination,
                  search_query: searchQuery,
                });
              }}
            />
          </div>
        )}
      </div>
      <div className="p-10 mt-0 bg-white rounded-[24px]">
        <div className="flex md:flex-row flex-col gap-5 justify-between items-center">
          <div className="text-stone-900 text-xl font-medium leading-tight">
            Your personalized assistants
          </div>
          <div className="w-96 h-14  relative bg-white rounded-lg border border-neutral-300 cursor-pointer">
            <div
              className={
                "w-32 h-10 px-3 py-2.5 left-[8px] top-[8px] absolute rounded justify-center items-center gap-2 inline-flex" +
                `${tab === "All" ? " bg-primary-yellow font-medium" : ""}`
              }
              onClick={() => setTab("All")}
            >
              <div className="text-center text-stone-900 text-sm font-medium ">
                All
              </div>
            </div>
            <div
              className={
                "w-32 h-10 px-3 py-2.5 left-[120px] top-[8px] absolute rounded justify-center items-center gap-2 inline-flex" +
                `${
                  tab === "Created"
                    ? " bg-primary-yellow font-medium"
                    : "font-normal"
                } `
              }
              onClick={() => setTab("Created")}
            >
              <div className="text-center text-stone-900 text-sm  ">
                Created
              </div>
            </div>
            <div
              className={
                "w-32 h-10 px-3 py-2.5 right-[8px] top-[8px] absolute rounded justify-center items-center gap-2 inline-flex" +
                `${tab === "Factly" ? " bg-primary-yellow font-medium" : ""}`
              }
              onClick={() => setTab("Factly")}
            >
              <div className="text-center text-stone-900 text-sm font-medium ">
                Factly
              </div>
            </div>
          </div>
        </div>
        {/* This is Page Items */}
        <div className="grid gap-8 my-10 md:grid-cols-2 grid-cols-1">
          {factlyPersona.map(
            (persona, id) =>
              isRenderPersona(persona) && (
                <PersonaCard
                  meta_id={persona.key}
                  key={persona.key}
                  image={persona.image}
                  name={persona.name}
                  desc={persona.desc}
                  id={persona.id}
                  isDefault={true}
                  onCardClick={() => persona.onclick()}
                  isOwner={isOwner(organisations)}
                />
              )
          )}
          {window.REACT_APP_APP_ENV === "staging" &&
            openAIAsst?.map(
              (persona, id) =>
                isRenderPersona(persona) && (
                  <PersonaCard
                    meta_id={persona.key}
                    key={persona.key + id}
                    image={persona.image}
                    name={persona.name}
                    desc={persona.desc}
                    id={persona.id}
                    isDefault={true}
                    onCardClick={() => persona.onclick()}
                    isOwner={false}
                  />
                )
            )}
          {personaData.map(
            (persona, index) =>
              isRenderPersona(persona) && (
                <PersonaCard
                  key={index}
                  image={getURL(persona?.avatar)}
                  name={persona?.name}
                  desc={persona?.description}
                  id={persona?.id}
                  handleDelete={handleDelete}
                  isDefault={persona?.is_default}
                  onCardClick={() => {
                    navigate(`/personas/${persona?.id}/chat`);
                  }}
                />
              )
          )}
        </div>
        <Pagination
          totalPages={pagination.count}
          currentPage={pagination.page}
          setCurrentPage={setPagination}
        />
      </div>
    </>
  );
}

const isOwner = (orgs) => {
  return (
    orgs?.details?.find((org) => org?.id === orgs.selectedOrg)?.role === "owner"
  );
};
