import yellow from "../../assets/icons/yellow-indicator.svg";
import red from "../../assets/icons/red-indicator.svg";
import green from "../../assets/icons/green-indicator.svg";

export const MaxMessageClip = ({ msgCount }) => {
  const getIndicator = () => {
    if (msgCount <= 1) {
      return red;
    } else if (msgCount <= 3) {
      return yellow;
    } else {
      return green;
    }
  };

  return (
    <div
      className={`py-2 px-[10px] flex gap-[6px] border border-[#F0F0F0] rounded-3xl`}
    >
      <img src={getIndicator()} alt="indicator" />
      <span className={`text-[#1e1e1e] text-sm`}>{msgCount} messages left</span>
    </div>
  );
};

export const ConnectedClip = ({ connected }) => {
  return (
    <div
      className={`py-2 px-[10px] flex gap-[6px] border border-[#F0F0F0] rounded-3xl`}
    >
      <img src={connected ? green : red} alt="indicator" />
      <span className={`text-[#1e1e1e] text-sm`}>
        {connected ? "Connected" : "Connecting..."}
      </span>
    </div>
  );
};
