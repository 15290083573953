import React from "react";

import tagoreChatIcon from "../../assets/tagore-chat-icon.svg";
import tagoreWhiteIcon from "../../assets/tagore-ai-white.svg";

import { Outlet } from "react-router-dom";

function AuthLayout({ title }) {
  return (
    <div className="h-screen md:flex-row flex flex-col">
      <div className="relative bg-[#e93648] overflow-hidden flex w-1/2 justify-center items-center p-4">
        <img src={tagoreChatIcon}></img>
        <div className={`absolute bottom-10 w-full flex justify-center`}>
          <img src={tagoreWhiteIcon}></img>
        </div>
      </div>
      <div className="flex flex-col gap-10 md:w-1/2 justify-center py-10 px-16 md:px-36 items-center bg-[#F3F5F8]">
        <Outlet />
      </div>
    </div>
  );
}

export default AuthLayout;
