import React from "react";

const UnauthorizedPage = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md">
        <h1 className="text-2xl font-semibold text-red-500 mb-4">
          Unauthorized Access
        </h1>
        <p className="text-gray-600 mb-4">
          Sorry, you do not have permission to access this page.
        </p>
        <p className="text-gray-600">
          Please contact your administrator for assistance.
        </p>
      </div>
    </div>
  );
};

export default UnauthorizedPage;
