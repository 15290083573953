import { useSelector } from "react-redux";
import { UnauthorisedAccess } from "../../pages/errors/unauthorised";
import CentralLoading from "../loader/centralLoading";

export const withOrg =
  (Component, loader = false) =>
  (props) => {
    const {
      selectedOrgID,
      organisations,
      xUser,
      profileLoading,
      hasAccess,
      accessLoading,
    } = useSelector(({ organisations, profile, appAccess }) => {
      return {
        selectedOrgID: organisations?.selectedOrg,
        xUser: profile?.details,
        organisations,
        profileLoading: profile.loading,
        hasAccess: appAccess?.access,
        accessLoading: appAccess?.loading,
      };
    });
    if (
      organisations.loading ||
      profileLoading ||
      accessLoading ||
      selectedOrgID === -1
    ) {
      return <CentralLoading />;
    }

    if (!hasAccess) {
      return <UnauthorisedAccess organisations={organisations} />;
    }
    if (loader) {
      return xUser.id ? (
        <Component {...props} selectedOrg={selectedOrgID} userID={xUser.id} />
      ) : (
        loader
      );
    }

    return (
      <Component {...props} selectedOrg={selectedOrgID} userID={xUser.id} />
    );
  };
