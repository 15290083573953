import { Link, useNavigate } from "react-router-dom";
import { CreateButton } from "../../components/buttons/CreateButton";
import Search, { ExpandableSearch } from "../../components/search";
import Modal from "../../components/Modal";
import { useEffect, useState } from "react";
import { deleteDocument, getDocuments } from "../../actions/text";
import moment from "moment";
import { errorToast, successToast } from "../../util/toasts";
import { getKratosSessionDetails } from "../../actions/kratos";
import useWindowSize from "../../hooks/useWindowSize";
import { withOrg } from "../../components/organisation/withOrg";
import { ReactComponent as DotsThreeCircle } from "../../assets/icons/DotsThreeCircle.svg";
import { TopNav } from "../../components/layout/sidebar";

function DocumentPage({ selectedOrg }) {
  const [documentPageData, setDocumentPageData] = useState({
    count: 0,
    data: [],
  });

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    search_query: "",
  });

  let navigate = useNavigate();
  const tableHeader = [
    {
      name: "Name",
      width: "w-1/4",
    },
    {
      name: "Created By",
      width: "w-1/4",
    },
    {
      name: "Last Modified",
      width: "w-1/4",
    },
    {
      name: "Actions",
      width: "w-1/4",
    },
  ];

  const fetchDocuments = async () => {
    getDocuments(
      pagination.limit,
      pagination.page,
      pagination.search_query,
      selectedOrg
    )
      .then((response) => {
        setDocumentPageData({
          count: response.count,
          data: response.documents,
        });
      })
      .catch((error) => {
        errorToast(error?.message);
      });
  };

  useEffect(() => {
    fetchDocuments();
  }, [pagination.limit, pagination.page]);

  useEffect(() => {
    let timer = setTimeout(() => {
      setPagination({
        ...pagination,
        page: 1,
      });
      fetchDocuments();
    }, 500);
    return () => clearTimeout(timer);
  }, [pagination.search_query]);

  const tableStyles = {
    valuesPadding: "px-4 py-6",
    headerPadding: "p-4",
  };

  const [email, setEmail] = useState("");

  useEffect(() => {
    getKratosSessionDetails()
      .then((response) => {
        setEmail(response?.identity?.traits?.email);
      })
      .catch((error) => {
        errorToast(error?.message);
      });
  }, []);

  const handleDelete = (id) => {
    deleteDocument(id)
      .then(() => {
        if (documentPageData.data?.length === 1 && pagination.page !== 1) {
          setPagination({
            ...pagination,
            page: pagination.page - 1,
          });
        }
        fetchDocuments();
        successToast("Document deleted successfully");
      })
      .catch((error) => {
        errorToast(error?.message);
      });
  };
  const { isMobileScreen } = useWindowSize();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  return (
    // this is the main page container
    <div className="my-16 mt-0  min-w-[230px] ">
      {/* This is the page header */}
      <div className="flex pl-10 pr-9 flex-col py-7 ">
        <div className="w-full flex flex-row  items-center justify-between gap-5 mt-8 md:mt-0">
          <div className={`w-full  flex flex-row gap-5 items-center`}>
            {/* create document button */}
            <div>
              <Link to="/documents/create">
                <CreateButton
                  icon={false}
                  text={`${window.innerWidth < 420 ? "" : "Create Document"}`}
                />
              </Link>
            </div>
            {!(window.innerWidth < 1000) && (
              <div>
                <ExpandableSearch
                  placeholder={"search documents"}
                  onChange={(e) => {
                    setPagination({
                      ...pagination,
                      search_query: e.target.value,
                    });
                  }}
                />
              </div>
            )}
            <div className="ml-auto">
              <TopNav />
            </div>
          </div>
        </div>
        {window.innerWidth < 1000 && (
          <div className="mt-5 flex justify-center items-center min-w-[230px] ">
            (
            <Search
              placeholder={"Search documents"}
              onChange={(e) => {
                setPagination({
                  ...pagination,
                  search_query: e.target.value,
                });
              }}
            />
            )
          </div>
        )}
      </div>
      {/* This is the page body */}
      <div className="bg-white rounded-[24px] min-h-[86vh] py-8">
        <div className="m-8 max-w-screen ">
          {documentPageData.data.map((value, index) => {
            return (
              <div
                onClick={() => {
                  navigate(`/documents/create?id=${value.id}&isEdit=true`);
                }}
                className="container flex items-center  justify-between border hover:border-primary-yellow border-primary-border rounded-xl p-3 mb-4 cursor-pointer"
                key={index}
              >
                <div className="details container flex flex-col gap-3  ">
                  <div className="heading text-base leading-5 text-[#1e1e1e] font-medium">
                    {value.title}
                  </div>
                  <div className="sub details text-xs leading-5">
                    <span className="text-primary-grey">
                      {moment(value.updated_at).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}{" "}
                      •{" "}
                    </span>
                    <span className="text-[#798897]">{email}</span>
                  </div>
                </div>
                <DocumentOptions
                  onEdit={(e) => {
                    e.stopPropagation();
                    navigate(`/documents/create?id=${value.id}&isEdit=true`);
                  }}
                  onDelete={(e) => {
                    e.stopPropagation();
                    setDeleteId(value.id);
                    setShowDeleteModal(true);
                  }}
                />
              </div>
            );
          })}
        </div>
        <Modal
          open={showDeleteModal}
          onClose={() => {
            setShowDeleteModal(false);
          }}
          closeButton={false}
        >
          <div className="text-left">
            <h3 className="text-xl font-medium mb-4 border-b pb-4 border-gray-300">
              Delete Document
            </h3>
            <div className="bg-red-100 p-4 text-[#FF0000] rounded-md mb-4 max-w-md font-semibold">
              Continuing with this action will delete the document permanently.
              Do you really want to delete the document?
            </div>
            <div className="flex justify-end w-full gap-4 border-t pt-4 border-gray-300">
              <div>
                <button
                  className="bg-background-secondary hover:bg-gray-200 text-gray-800 py-2 px-4 rounded"
                  onClick={() => {
                    setShowDeleteModal(false);
                    setDeleteId("");
                  }}
                >
                  Cancel
                </button>
              </div>
              <div>
                <button
                  className="bg-[#FF0000] hover:bg-red-600 text-white py-2 px-4 rounded"
                  onClick={() => {
                    handleDelete(deleteId);
                    setShowDeleteModal(false);
                    setDeleteId("");
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <Pagination
          documentPageData={documentPageData}
          pagination={pagination}
          setPagination={setPagination}
        />
      </div>
    </div>
  );
}

const Pagination = ({ documentPageData, pagination, setPagination }) => {
  const generateOptions = () => {
    const options = [];
    const maxOption = Math.min(documentPageData.count, 30); // Limit the options to multiples of 10 up to 30

    for (let i = 10; i <= maxOption; i += 10) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }

    if (documentPageData.count > 30) {
      options.push(
        <option key="all" value={documentPageData.count}>
          All
        </option>
      );
    }

    if (!options.length) {
      // Add a default option if no options were generated
      options.push(
        <option key="default" value={10}>
          10
        </option>
      );
    }

    return options;
  };

  return (
    <div className="page-sizer mx-10 mt-4 text-right text-sm text-primary-grey">
      {1} -{" "}
      {documentPageData.count > pagination.limit
        ? pagination.limit
        : documentPageData.count}{" "}
      of {documentPageData.count} ∙ Display{" "}
      <select
        value={pagination.limit}
        onChange={(e) => {
          setPagination((prev) => ({
            ...prev,
            page: 1,
            limit: parseInt(e.target.value),
          }));
        }}
        className="inline border border-primary-border rounded-md px-2 py-1 mx-1 text-primary-grey"
      >
        {generateOptions()}
      </select>{" "}
      rows
    </div>
  );
};

const DocumentOptions = ({ onEdit, onDelete }) => {
  const [showOptions, setShowOptions] = useState(false);
  useEffect(() => {
    const handleDocumentClick = () => {
      setShowOptions(false);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  return (
    <div className="relative">
      <DotsThreeCircle
        className="cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          setShowOptions(!showOptions);
        }}
      />
      {showOptions && (
        <div className="absolute top-full right-0 mt-2 bg-white border border-primary-border rounded-md shadow-md z-10">
          <div
            className="py-2 px-4 cursor-pointer hover:bg-[#007BFF] hover:text-white"
            onClick={onEdit}
          >
            Edit
          </div>
          <div
            className="py-2 px-4 cursor-pointer hover:bg-[#FF0000] hover:text-white"
            onClick={onDelete}
          >
            Delete
          </div>
        </div>
      )}
    </div>
  );
};

export default withOrg(DocumentPage);
