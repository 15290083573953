import { useEffect } from "react";
import useDarkMode from "./hooks/useDarkMode";
import useWindowSize from "./hooks/useWindowSize";
import animationData from "./assets/lottiefiles/unauthorised.json";
import Lottie from "react-lottie";

export default function Tagore({ children }) {
  const { darkMode } = useDarkMode();

  useEffect(() => {
    const html = document.querySelector("html");
    if (darkMode) {
      html.classList.add("dark");
    } else {
      html.classList.remove("dark");
    }
  }, [darkMode]);

  const { isDesktopScreen } = useWindowSize();

  if (!isDesktopScreen) {
    return (
      <div className="w-screen h-screen flex flex-col justify-center items-center gap-4">
        <div className="w-full flex flex-col justify-center">
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: animationData,
            }}
            height={300}
            width={300}
          />
        </div>
        <h1 className="text-center text-3xl font-semibold">
          Sorry, this page is not available on mobile
        </h1>
        <p className="text-center text-gray-500 w-3/5">
          Please use a desktop or laptop to access Tagore.
        </p>
      </div>
    );
  }

  return <>{children}</>;
}
