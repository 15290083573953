import Lottie from "react-lottie";
import animationData from "../../assets/lottiefiles/unauthorised.json";
import { Link } from "react-router-dom";
import { logout } from "../../actions/kratos";
import { errorToast } from "../../util/toasts";
import Select from "react-select";
import { useDispatch } from "react-redux";

export function UnauthorisedAccess({
  title = "Unauthorized Access",
  description = "Currently we are only allowing early access to our platform. If you want to get early access, please send us an email at",
  link = "mailto:tagoreai@factly.in",
  linkText = "tagoreai@factly.in",
  showLogout = true,
  organisations,
}) {
  const dispatch = useDispatch();

  const handleLogout = () => {
    logout()
      .then((res) => {
        window.location.href = res.logout_url;
      })
      .catch(() => {
        errorToast("error logging out");
      });
  };

  const descriptionWithSelector =
    "Currently we are only allowing early access to our platform. Please select the organisation access has been granted to. If you want to get early access, please send us an email at";

  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center gap-4">
      <div className="w-full flex flex-col justify-center">
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: animationData,
          }}
          height={600}
          width={600}
        />
      </div>
      <h1 className="text-center text-3xl font-semibold">{title}</h1>
      <p className="text-center text-gray-500 w-3/5">
        {organisations?.details?.length > 1
          ? descriptionWithSelector
          : description}
      </p>
      {organisations?.details?.length > 1 && (
        <Select
          className="w-40"
          options={organisations?.details?.map((org) => {
            return {
              label: org?.title,
              value: org.id,
            };
          })}
          placeholder="Select Organisation"
          value={{
            value: organisations?.selectedOrg,
            label: organisations?.details?.find(
              (org) => org.id === organisations?.selectedOrg
            )?.title,
          }}
          onChange={(selectedOption) => {
            dispatch({
              type: "SELECT_ORG",
              payload: selectedOption.value,
            });
          }}
        />
      )}
      <Link
        to={link}
        className="w-full flex justify-center text-blue-500 text-lg"
      >
        {linkText}
      </Link>
      {showLogout && (
        <span
          className="w-full flex justify-center text-blue-500 text-sm cursor-pointer"
          onClick={() => {
            handleLogout();
          }}
        >
          Logout
        </span>
      )}
    </div>
  );
}
