import { Microphone, Pause } from "@phosphor-icons/react";
import { theme } from "../commons/styles";
import { useRef } from "react";

// AudioRecorder component takes care of recording audio
// and sending it to the server
export const AudioRecorder = ({ getAudioBlob, recording, setRecording }) => {
  const mediaRecorder = useRef(null);

  const handleStartRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      mediaRecorder.current = new MediaRecorder(stream);
      mediaRecorder.current.ondataavailable = handleDataAvailable;
      mediaRecorder.current.onstop = handleStop;
      mediaRecorder.current.start();
      setRecording(true);
    });
  };

  const handleDataAvailable = (event) => {
    if (event.data.size > 0) {
      let url = URL.createObjectURL(event.data);
      getAudioBlob(url);
    }
  };

  const handleStop = () => {
    setRecording(false);
  };

  const handleStopRecording = () => {
    if (mediaRecorder.current) {
      mediaRecorder.current.stop();
    }
  };

  return (
    <>
      {!recording ? (
        <Microphone
          size={theme.iconSize.large}
          color={theme.iconColor.secondary}
          cursor={"pointer"}
          onClick={handleStartRecording}
        />
      ) : (
        <Pause
          size={theme.iconSize.large}
          color={theme.iconColor.secondary}
          cursor={"pointer"}
          onClick={() => {
            handleStopRecording();
          }}
        />
      )}
    </>
  );
};
