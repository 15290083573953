export function replaceKeys(originalObject, keyMap) {
  const newObject = {};

  Object.keys(originalObject).forEach((key) => {
    const newKey = keyMap[key] || key;
    newObject[newKey] = originalObject[key];
  });

  return newObject;
}

// Example usage:
//   const originalObject = {
//     oldKey1: 'value1',
//     oldKey2: 'value2',
//     oldKey3: 'value3',
//   };

//   const keyMap = {
//     oldKey1: 'newKey1',
//     oldKey2: 'newKey2',
//     // You can skip keys if you don't want to replace them
//   };
