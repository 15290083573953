import { CiSearch } from "react-icons/ci";
import SearchButton from "../buttons/SearchButton";
import { ReactComponent as MagnifyingGlass } from "../../assets/icons/MagnifyingGlass.svg";
import { useState, useRef, useEffect } from "react";
import useDarkMode from "../../hooks/useDarkMode";
import useWindowSize from "../../hooks/useWindowSize";
import useOutsideClick from "../../hooks/useOutsideClick";
export default function Search({ placeholder, onChange, handleSearch, width }) {
  const { isMobileScreen } = useWindowSize();
  const { darkMode } = useDarkMode();
  return (
    <div
      className={`${
        window.innerWidth < 1000 ? " w-full" : "w-8/12 min-w-[250px]"
      } rounded-lg border border-primary-border flex flex-row items-center justify-between p-3 ${
        darkMode && "border-border-primary-alt bg-[#1E1E1E]"
      }`}
      style={{ width: width }}
    >
      <div className="flex items-center gap-2 w-5/6">
        {/* <CiSearch size={"20px"} color={`#667085`} /> */}
        <MagnifyingGlass />
        <input
          placeholder={placeholder}
          className={`border-none outline-none w-4/5 
          text-stone-500 text-sm font-normal 
          ${darkMode && "bg-[#1E1E1E]"}`}
          onChange={onChange}
        ></input>
      </div>
      {/* <SearchButton
        background={darkMode ? "white" : "black"}
        textColor={darkMode ? "black" : "white"}
        onClick={() => handleSearch()}
      /> */}
    </div>
  );
}

export const ExpandableSearch = ({
  placeholder,
  onChange,
  handleSearch,
  width,
}) => {
  const { isMobileScreen } = useWindowSize();
  const { darkMode } = useDarkMode();
  const [showInput, setShowInput] = useState(false);
  const ref = useRef();
  const handleClick = () => {
    setShowInput((prev) => {
      return !prev;
    });
  };
  useEffect(() => {
    if (showInput) {
      ref?.current?.focus();
    }
  }, [showInput]);

  const handleOutsideClick = () => {
    setShowInput((prev) => !prev);
  };
  useOutsideClick(ref, handleOutsideClick);
  return (
    <div
      onClick={handleClick}
      className={`w-12 rounded-3xl 
    ${
      showInput
        ? "rounded-lg w-fit min-w-[250px] p-3 h-auto border border-stone-500"
        : "h-12  border border-stone-500"
    }
    flex flex-row items-center justify-between`}
      // style={{ transition: "0.5s" }}
    >
      <div className="flex items-center gap-2 w-full justify-center">
        <MagnifyingGlass />
        {showInput && (
          <input
            ref={ref}
            onClick={(e) => e.stopPropagation()}
            placeholder={placeholder}
            className={`border-none bg-inherit outline-none w-4/5 
          text-stone-500 text-sm font-normal 
          ${darkMode && "bg-[#1E1E1E]"}`}
            onChange={onChange}
          ></input>
        )}
      </div>
    </div>
  );
};
